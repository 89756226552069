<template>
  <div :class="$style['game-links']">
    <div
      ref="links"
      :class="$style['game-links-inner']"
      data-test="game-links-inner"
    >
      <NavLink
        v-for="game in games"
        :key="game.name"
        :to="game.url"
        :class="$style['game-link']"
        @mouseenter="showGameName($event, game.name)"
        @mouseleave="hideGameName"
      >
        <component :is="components[game.icon]"></component>
      </NavLink>
      <Transition name="game-name">
        <div
          v-if="gameNameIsRequired"
          :style="{ top: `${gameNameTop}px` }"
          :class="$style['game-name']"
        >
          {{ gameName }}
        </div>
      </Transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import CrashIcon from "~/components/Icon/Crash.vue";
import LadderIcon from "~/components/Icon/Ladder.vue";
import RouletteIcon from "~/components/Icon/Roulette.vue";
import SapperIcon from "~/components/Icon/Sapper.vue";
import TowerIcon from "~/components/Icon/Tower.vue";
import UpgradeIcon from "~/components/Icon/Upgrade.vue";
import WheelIcon from "~/components/Icon/Wheel.vue";
import ContractsIcon from "~/components/Icon/Contracts.vue";
import { normalize } from "~/utils/url/normalize";

const components: Record<string, any> = {
  CrashIcon,
  LadderIcon,
  RouletteIcon,
  SapperIcon,
  TowerIcon,
  UpgradeIcon,
  WheelIcon,
  ContractsIcon,
};
const { t } = useI18n();
const links = ref<HTMLElement | null>(null);
const gameName = ref<string | null>(null);
const gameNameTop = ref<number | null>(null);
const gameNameIsRequired = ref(false);
const platform = computed(() => usePlatform());
const isSapperEnabled = useFlag("miner");

const games = computed(() => {
  const { basePath } = platform.value;
  const list = [
    {
      icon: "UpgradeIcon",
      url: normalize(`${basePath}/games/upgrade`),
      name: "upgrade",
    },
    {
      icon: "ContractsIcon",
      url: normalize(`${basePath}/games/contracts`),
      name: "contracts",
    },
    {
      icon: "RouletteIcon",
      url: normalize(`${basePath}/games/roulette`),
      name: "roulette",
    },
    {
      icon: "LadderIcon",
      url: normalize(`${basePath}/games/ladder`),
      name: "ladder",
    },
    {
      icon: "CrashIcon",
      url: normalize(`${basePath}/games/crash`),
      name: "crash",
    },
    {
      icon: "WheelIcon",
      url: normalize(`${basePath}/games/wheel`),
      name: "wheel",
    },
    {
      icon: "TowerIcon",
      url: normalize(`${basePath}/games/tower`),
      name: "tower",
    },
  ];
  if (isSapperEnabled.value) {
    list.push({
      icon: "SapperIcon",
      url: normalize(`${basePath}/games/sapper`),
      name: "sapper",
    });
  }
  return list;
});

const showGameName = (e: MouseEvent, gameNameValue: string) => {
  if (
    !links.value ||
    !(links.value instanceof HTMLElement) ||
    !e.target ||
    !(e.target instanceof HTMLElement)
  )
    return;

  const { top: containerTop } = links.value.getBoundingClientRect();
  const { top, height } = e.target.getBoundingClientRect();

  gameName.value = t(`mini_games.${gameNameValue}`);
  gameNameTop.value = top - containerTop + height / 2;
  gameNameIsRequired.value = true;
};

const hideGameName = () => {
  gameNameIsRequired.value = false;
  gameName.value = null;
  gameNameTop.value = null;
};
</script>

<style lang="scss" scoped>
.game-name-enter-from,
.game-name-leave-to {
  opacity: 0;
  transform: translate(20px, -50%);
}

.game-name-enter-active,
.game-name-leave-active {
  transition: 0.15s;
}

.router-link-exact-active {
  background: var(--general-accident);
  svg {
    fill: var(--button-accident-content);
  }
}
</style>

<style lang="scss" module>
.game-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  transition: background-color 0.2s linear;

  &:not(:last-of-type) {
    margin-bottom: 12px;
  }

  &:hover {
    background-color: var(--button-tonal-hover);
    svg {
      fill: var(--general-white);
    }
  }

  svg {
    width: 24px;
    fill: var(--general-dark-grey);
    transition: 0.25s;
  }
}

.game-links {
  position: relative;
  height: 100%;
  &-inner {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: var(--general-transparent-dark-75);
    border-radius: 20px;
    padding: 12px;
    width: 100%;
    border: 0.6px solid var(--general-stroke);
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
      width: 3px;
      border-radius: $br5;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      margin: 20px 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $grey-3;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: lighten($color: $grey-3, $amount: 5%);
    }
  }
}
.game-name {
  position: absolute;
  z-index: 1000;
  left: 70px;
  transform: translateY(-50%);
  width: 100px;
  padding: 8px;
  border-radius: $br10;
  background-color: var(--general-transparent-mid-55);
  backdrop-filter: blur(40px);
  font-size: 0.8125rem;
  color: var(--general-white);
  text-align: center;
  box-shadow: 0 5px 5px rgba($color: #000, $alpha: 0.25);
}
</style>
