<template>
  <div
    :class="$style['open-case-expression']"
    data-role="open-case-expression"
  >
    {{ $t("open_case.open_case") }}
  </div>
</template>

<style lang="scss" module>
.open-case-expression {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 150px;
  padding: 15px 20px;
  border-radius: $br10;
  font-size: 0.875rem;
  color: var(--general-white);
  text-transform: uppercase;
  text-align: center;
  line-height: 95%;
  background-color: var(--button-tonal-default);
  opacity: 0;
  transition: 0.25s;
}
</style>
