// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CurrenciesEnum } from './CurrenciesEnum';
import {
    CurrenciesEnumFromJSON,
    CurrenciesEnumFromJSONTyped,
    CurrenciesEnumToJSON,
} from './CurrenciesEnum';
import type { RequiredFieldsEnum } from './RequiredFieldsEnum';
import {
    RequiredFieldsEnumFromJSON,
    RequiredFieldsEnumFromJSONTyped,
    RequiredFieldsEnumToJSON,
} from './RequiredFieldsEnum';

/**
 * 
 * @export
 * @interface PaymentMethod
 */
export interface PaymentMethod {
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    readonly uri: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    fee: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    icon: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentMethod
     */
    readonly isActive: boolean;
    /**
     * 
     * @type {number}
     * @memberof PaymentMethod
     */
    readonly minAmount: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    fixFee: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentMethod
     */
    isSecondary: boolean;
    /**
     * 
     * @type {Array<CurrenciesEnum>}
     * @memberof PaymentMethod
     */
    currencies: Array<CurrenciesEnum>;
    /**
     * 
     * @type {Array<RequiredFieldsEnum>}
     * @memberof PaymentMethod
     */
    readonly requiredFields: Array<RequiredFieldsEnum>;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    type: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentMethod
     */
    readonly canRetrievePaymentData: boolean;
}

/**
 * Check if a given object implements the PaymentMethod interface.
 */
export function instanceOfPaymentMethod(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "uri" in value;
    isInstance = isInstance && "fee" in value;
    isInstance = isInstance && "icon" in value;
    isInstance = isInstance && "isActive" in value;
    isInstance = isInstance && "minAmount" in value;
    isInstance = isInstance && "fixFee" in value;
    isInstance = isInstance && "isSecondary" in value;
    isInstance = isInstance && "currencies" in value;
    isInstance = isInstance && "requiredFields" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "canRetrievePaymentData" in value;

    return isInstance;
}

export function PaymentMethodFromJSON(json: any): PaymentMethod {
    return PaymentMethodFromJSONTyped(json, false);
}

export function PaymentMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentMethod {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'title': json['title'],
        'uri': json['uri'],
        'fee': json['fee'],
        'icon': json['icon'],
        'isActive': json['is_active'],
        'minAmount': json['min_amount'],
        'fixFee': json['fix_fee'],
        'isSecondary': json['is_secondary'],
        'currencies': ((json['currencies'] as Array<any>).map(CurrenciesEnumFromJSON)),
        'requiredFields': ((json['required_fields'] as Array<any>).map(RequiredFieldsEnumFromJSON)),
        'type': json['type'],
        'canRetrievePaymentData': json['can_retrieve_payment_data'],
    };
}

export function PaymentMethodToJSON(value?: PaymentMethod | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'title': value.title,
        'fee': value.fee,
        'icon': value.icon,
        'fix_fee': value.fixFee,
        'is_secondary': value.isSecondary,
        'currencies': ((value.currencies as Array<any>).map(CurrenciesEnumToJSON)),
        'type': value.type,
    };
}

