<template>
  <div
    :class="$style['cases-block']"
    data-test="cases-block"
  >
    <slot />
  </div>
</template>

<style lang="scss" module>
.cases-block {
  margin-bottom: 30px;
}
</style>
