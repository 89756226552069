// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ActivePromoCodeRequest } from './ActivePromoCodeRequest';
import {
    ActivePromoCodeRequestFromJSON,
    ActivePromoCodeRequestFromJSONTyped,
    ActivePromoCodeRequestToJSON,
} from './ActivePromoCodeRequest';
import type { LanguageEc4Enum } from './LanguageEc4Enum';
import {
    LanguageEc4EnumFromJSON,
    LanguageEc4EnumFromJSONTyped,
    LanguageEc4EnumToJSON,
} from './LanguageEc4Enum';
import type { NicknameDecorationsEnum } from './NicknameDecorationsEnum';
import {
    NicknameDecorationsEnumFromJSON,
    NicknameDecorationsEnumFromJSONTyped,
    NicknameDecorationsEnumToJSON,
} from './NicknameDecorationsEnum';
import type { PermissionsEnum } from './PermissionsEnum';
import {
    PermissionsEnumFromJSON,
    PermissionsEnumFromJSONTyped,
    PermissionsEnumToJSON,
} from './PermissionsEnum';

/**
 * 
 * @export
 * @interface PatchedUserRequest
 */
export interface PatchedUserRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchedUserRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedUserRequest
     */
    nickname?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedUserRequest
     */
    banInChat?: boolean;
    /**
     * 
     * @type {ActivePromoCodeRequest}
     * @memberof PatchedUserRequest
     */
    activePromoCode?: ActivePromoCodeRequest;
    /**
     * 
     * @type {Array<PermissionsEnum>}
     * @memberof PatchedUserRequest
     */
    permissions?: Array<PermissionsEnum> | null;
    /**
     * 
     * @type {NicknameDecorationsEnum}
     * @memberof PatchedUserRequest
     */
    selectedNicknameDecoration?: NicknameDecorationsEnum | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedUserRequest
     */
    phone?: string | null;
    /**
     * 
     * @type {LanguageEc4Enum}
     * @memberof PatchedUserRequest
     */
    language?: LanguageEc4Enum;
}

/**
 * Check if a given object implements the PatchedUserRequest interface.
 */
export function instanceOfPatchedUserRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PatchedUserRequestFromJSON(json: any): PatchedUserRequest {
    return PatchedUserRequestFromJSONTyped(json, false);
}

export function PatchedUserRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedUserRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'nickname': !exists(json, 'nickname') ? undefined : json['nickname'],
        'banInChat': !exists(json, 'ban_in_chat') ? undefined : json['ban_in_chat'],
        'activePromoCode': !exists(json, 'active_promo_code') ? undefined : ActivePromoCodeRequestFromJSON(json['active_promo_code']),
        'permissions': !exists(json, 'permissions') ? undefined : (json['permissions'] === null ? null : (json['permissions'] as Array<any>).map(PermissionsEnumFromJSON)),
        'selectedNicknameDecoration': !exists(json, 'selected_nickname_decoration') ? undefined : NicknameDecorationsEnumFromJSON(json['selected_nickname_decoration']),
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'language': !exists(json, 'language') ? undefined : LanguageEc4EnumFromJSON(json['language']),
    };
}

export function PatchedUserRequestToJSON(value?: PatchedUserRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'nickname': value.nickname,
        'ban_in_chat': value.banInChat,
        'active_promo_code': ActivePromoCodeRequestToJSON(value.activePromoCode),
        'permissions': value.permissions === undefined ? undefined : (value.permissions === null ? null : (value.permissions as Array<any>).map(PermissionsEnumToJSON)),
        'selected_nickname_decoration': NicknameDecorationsEnumToJSON(value.selectedNicknameDecoration),
        'phone': value.phone,
        'language': LanguageEc4EnumToJSON(value.language),
    };
}

