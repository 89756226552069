<template>
  <DecoratedNickname
    v-if="session.user"
    :value="session.user?.selectedNicknameDecoration"
    :class="$style.nickname"
  >
    {{ truncateString(session.user.nickname, 10) }}
  </DecoratedNickname>
</template>

<script setup lang="ts">
const userStore = useUserStore();
const { session } = storeToRefs(userStore);
</script>

<style lang="scss" module>
.nickname {
  margin-bottom: 5px;
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--general-white);
  text-transform: uppercase;
}
</style>
