import FingerprintJs from "@fingerprintjs/fingerprintjs";

import type { User, Socials, DefaultAvatar, AvatarBorder } from "~/api_gen";
import type { AuthFormNames } from "~/types";
import { removeTokens } from "~/utils/tokens/removeTokens";
import { normalize } from "~/utils/url/normalize";
import { useMonthCookie } from "~/utils/cookie";
import { usePlatformSelection } from "~/composables/app/usePlatformSelection";

const USER_ID_COOKIE_NAME = "userId";

export const useUserStore = defineStore("userStore", () => {
  const session = ref<{
    isAuth: boolean;
    user: User | null;
  }>({
    isAuth: false,
    user: null,
  });
  const socialIds = ref<Socials | null>(null);
  const vkUnbindDate = ref<{
    days: number;
    hours: number;
    minutes: number;
    second: number;
  } | null>(null);
  const bonusRub = ref(0);
  const bonusGold = ref(0);
  const authPopupIsRequired = ref(false);
  const onboardingPopupIsRequired = ref(false);
  const nameRegisterPopupIsRequired = ref(false);
  const authForm = ref<null | AuthFormNames>(null);
  const changePasswordPopupIsRequired = ref(false);
  const userIsResolved = ref(false);
  const userIsAuthorized = computed(() => session.value.isAuth);
  const balance = computed<number | undefined>(
    () => session.value.user?.balance,
  );
  const unleashManager = useUnleashManager();
  const posthog = usePosthog();
  // Индикатор таймера повтороного смс.
  const beelineSmsTimer = ref(false);
  const defaultAvatars = ref<DefaultAvatar[]>([]);
  const avatarBorders = ref<AvatarBorder[]>([]);

  function throwUserIdMismatchError({
    cookieValue,
    comparedValue,
  }: {
    cookieValue: string | number;
    comparedValue: string | number;
  }) {
    throw new Error(
      `User id mismatch: value from "userId" cookie "${cookieValue}" is not equal to "${comparedValue}"`,
    );
  }

  function showAuth(formName: AuthFormNames) {
    if (useMobileCheck()) {
      window.scrollTo(0, 0);
    }
    document.documentElement.style.overflow = "hidden";
    document.body.style.overflow = "hidden";
    authPopupIsRequired.value = true;
    authForm.value = formName;
    document.documentElement.classList.add("is-locked");
  }

  function hideAuth() {
    authPopupIsRequired.value = false;
    authForm.value = null;
    document.documentElement.style.overflow = "";
    document.body.style.overflow = "";
    document.documentElement.classList.remove("is-locked");
  }

  function changeAuthForm(formName: AuthFormNames) {
    authForm.value = formName;
  }

  function updateUserData(userData: User) {
    session.value = {
      isAuth: true,
      user: userData,
    };
    const userIdCookie = useMonthCookie(USER_ID_COOKIE_NAME);
    userIdCookie.value = userData.id.toString();
  }

  function logout() {
    session.value = {
      isAuth: false,
      user: null,
    };
    clearStorageReferral();
    socialIds.value = null;
    removeTokens();
    useCookie(USER_ID_COOKIE_NAME).value = null;
    unleashManager.useSessionId();
    if (posthog) {
      posthog.reset();
    }
  }

  // Убираем ключи для реферралов
  function clearStorageReferral() {
    // проверяем наличие ключа. Если есть, удаляем
    if (isClient()) {
      if (localStorage.getItem("ref")) localStorage.removeItem("ref");
      if (localStorage.getItem("partner_id"))
        localStorage.removeItem("partner_id");
      if (localStorage.getItem("offer_id")) localStorage.removeItem("offer_id");
    }
  }

  async function getUser() {
    const api = useApi();
    const userData = await api.user.profileRetrieve();
    const cookieValue = useMonthCookie(USER_ID_COOKIE_NAME).value;
    const comparedValue = userData.id;
    if (cookieValue && cookieValue.toString() !== comparedValue.toString()) {
      logout();
      throwUserIdMismatchError({
        cookieValue,
        comparedValue,
      });
    }
    updateUserData(userData);
    return userData;
  }

  async function getAllUserData() {
    const notificationsStore = useNotificationsStore();
    const exchangerEventStore = useExchangerEventStore();
    if (!userIsAuthorized.value) {
      try {
        await pause();
        const data = await getUser();
        const userId = data.id.toString();
        unleashManager.useUserId(userId);
        if (posthog) {
          posthog.identify(userId);
        }
      } catch (error) {
        return;
      } finally {
        resolveVisitorStatus();
      }
    }
    if (isClient()) {
      pause(1000)
        .then(() => notificationsStore.getNoScannedNotifications())
        .then(() => pause(1000))
        .then(() => exchangerEventStore.checkReward());
    }
  }

  function markUserAsBannedInChat() {
    if (session.value.user) {
      session.value.user.banInChat = true;
    }
  }

  function markUserAsUnbannedInChat() {
    if (session.value.user) {
      session.value.user.banInChat = false;
    }
  }

  async function handleVisit() {
    const userIdCookie = useMonthCookie(USER_ID_COOKIE_NAME);
    if (!userIdCookie.value) {
      resolveVisitorStatus();
      return;
    }
    await getAllUserData();
  }

  function showOnboarding() {
    onboardingPopupIsRequired.value = true;
  }
  function hideOnboarding() {
    onboardingPopupIsRequired.value = false;
    setTimeout(() => {
      usePlatformSelection();
    }, 500);
  }
  function showNameRegister() {
    nameRegisterPopupIsRequired.value = true;
  }
  function hideNameRegister() {
    nameRegisterPopupIsRequired.value = false;
    setTimeout(() => {
      showOnboarding();
    }, 500);
  }
  function resolveVisitorStatus() {
    userIsResolved.value = true;
  }

  function showChangePasswordWindow() {
    document.documentElement.style.overflow = "hidden";
    changePasswordPopupIsRequired.value = true;
  }

  function hideChangePasswordWindow() {
    changePasswordPopupIsRequired.value = false;
    document.documentElement.style.overflow = "";
  }

  async function loginLog() {
    if (isServer()) return Promise.resolve();
    const fp = await FingerprintJs.load();
    const fpResult = await fp.get();
    const fingerprint = fpResult.visitorId;
    const api = useApi();
    return api.user.loginLogCreate({
      loginLogRequest: {
        fingerprint,
      },
    });
  }

  async function bindReferralData() {
    if (isServer()) return;
    const offerId = localStorage.getItem("offer_id");
    const partnerId = localStorage.getItem("partner_id");
    const clickId = localStorage.getItem("click_id");
    const api = useApi();
    const fd = new FormData();
    if (clickId && offerId && partnerId) {
      fd.append("offer_id", offerId);
      fd.append("partner_id", partnerId);
      fd.append("click_id", clickId);
      return api.affise.bind({
        affiseDataRequest: {
          offerId: Number(offerId),
          partnerId: Number(partnerId),
          clickId: clickId,
        },
      });
    } else if (offerId && partnerId) {
      fd.append("offer_id", offerId);
      fd.append("partner_id", partnerId);
      return api.affise.bind({
        affiseDataRequest: {
          offerId: Number(offerId),
          partnerId: Number(partnerId),
        },
      });
    }
  }

  async function bindInternalReferral() {
    if (isServer()) return;
    const refValue = localStorage.getItem("ref");
    const parsedRefValue = refValue ? parseFloat(refValue) : undefined;
    if (parsedRefValue) {
      const api = useApi();
      api.referral.internalBind({
        linkBindRequest: {
          ref: parsedRefValue,
        },
      });
    }
  }

  function getSocialRedirectUri(social: string, platformCode?: string): string {
    const platformSuffix = platformCode ? `${platformCode}/` : "";
    const runtimeConfig = useRuntimeConfig();
    const apiBase = isServer()
      ? runtimeConfig.public.serverApiBase
      : runtimeConfig.public.clientApiBase || window.location.origin;
    if (!apiBase) throw new Error("No apiBase is defined");
    return normalize(`${apiBase}/${platformSuffix}/auth/${social}/redirect/`);
  }

  return {
    session,
    socialIds,
    vkUnbindDate,
    bonusRub,
    bonusGold,
    authPopupIsRequired,
    authForm,
    changePasswordPopupIsRequired,
    userIsResolved,
    userIsAuthorized,
    balance,
    onboardingPopupIsRequired,
    nameRegisterPopupIsRequired,
    beelineSmsTimer,
    defaultAvatars,
    avatarBorders,
    showAuth,
    hideAuth,
    changeAuthForm,
    updateUserData,
    logout,
    getUser,
    getAllUserData,
    markUserAsBannedInChat,
    markUserAsUnbannedInChat,
    handleVisit,
    resolveVisitorStatus,
    showChangePasswordWindow,
    hideChangePasswordWindow,
    showOnboarding,
    hideOnboarding,
    showNameRegister,
    hideNameRegister,
    clearStorageReferral,
    loginLog,
    bindReferralData,
    bindInternalReferral,
    getSocialRedirectUri,
  };
});
