// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `ru` - Россия
 * * `uz` - Узбекистан
 * @export
 */
export const LanguageF93Enum = {
    Ru: 'ru',
    Uz: 'uz'
} as const;
export type LanguageF93Enum = typeof LanguageF93Enum[keyof typeof LanguageF93Enum];


export function LanguageF93EnumFromJSON(json: any): LanguageF93Enum {
    return LanguageF93EnumFromJSONTyped(json, false);
}

export function LanguageF93EnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): LanguageF93Enum {
    return json as LanguageF93Enum;
}

export function LanguageF93EnumToJSON(value?: LanguageF93Enum | null): any {
    return value as any;
}

