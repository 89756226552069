import type {
  CaseListsByCollections,
  TelegramCase,
  TelegramCypherTapCase,
  VkCase,
} from "~/api_gen";
import type { PlatformName } from "~/types";

export const useCasesStore = defineStore("casesStore", () => {
  type Cases = Record<
    PlatformName,
    {
      paidCases: CaseListsByCollections[];
      vkCase: VkCase | null;
      tgCase: TelegramCase | null;
      tgCypherTapCase: TelegramCypherTapCase | null;
    }
  >;

  const cases: Cases = reactive({
    standoff: {
      paidCases: [],
      vkCase: null,
      tgCase: null,
      tgCypherTapCase: null,
    },
    cs2: {
      paidCases: [],
      vkCase: null,
      tgCase: null,
      tgCypherTapCase: null,
    },
    pubg: {
      paidCases: [],
      vkCase: null,
      tgCase: null,
      tgCypherTapCase: null,
    },
    roblox: {
      paidCases: [],
      vkCase: null,
      tgCase: null,
      tgCypherTapCase: null,
    },
    genshin: {
      paidCases: [],
      vkCase: null,
      tgCase: null,
      tgCypherTapCase: null,
    },
  });

  const refreshCasesCollection = ref(false);

  const showCasesSkeleton = computed(() => {
    const platform = usePlatform();
    return !cases[`${platform.code}`].paidCases.length;
  });

  async function getCases(withFree: boolean = true) {
    const api = useApi();
    const platform = usePlatform();

    try {
      cases[`${platform.code}`].paidCases = await api.cases.byCollectionsList();
    } catch (error) {
      /* empty */
    }

    if (withFree) {
      const isDetail = false;

      try {
        await pause();
        cases[`${platform.code}`].vkCase = await api.cases.vkRetrieve({
          isDetail,
        });
      } catch (error) {
        /* empty */
      }

      try {
        await pause();
        cases[`${platform.code}`].tgCase = await api.cases.telegramRetrieve({
          isDetail,
        });
      } catch (error) {
        /* empty */
      }

      // try {
      //   await pause();
      //   cases[`${platform.code}`].tgCypherTapCase =
      //     await api.cases.telegramCypherTapRetrieve({
      //       isDetail,
      //     });
      // } catch (error) {
      //   /* empty */
      // }
    }
  }

  function dropCases() {
    for (const key in cases) {
      cases[key as PlatformName].paidCases = [];
      cases[key as PlatformName].vkCase = null;
      cases[key as PlatformName].tgCase = null;
      cases[key as PlatformName].tgCypherTapCase = null;
    }
  }

  return {
    cases,
    refreshCasesCollection,
    showCasesSkeleton,
    getCases,
    dropCases,
  };
});
