<template>
  <Container :to="`/case/open/?id=${freeCase.location}`">
    <CaseImage
      :src="freeCase.img ? freeCase.img : freeCase.location"
      :case-name="freeCase.name"
      :case-price="0"
      case-type="Бесплатные"
    />
    <OpenCaseExpression data-role="raffle-open-case-expression" />
  </Container>
</template>

<script setup lang="ts">
import Container from "./CaseLink/Container.vue";
import CaseImage from "./CaseLink/CaseImage.vue";
import OpenCaseExpression from "./CaseLink/OpenCaseExpression.vue";
import type { TelegramCase, VkCase } from "~/api_gen";

const props = defineProps<{
  freeCase:
    | VkCase
    | TelegramCase
    | { name: string; location: string; img: string | null };
}>();
const { freeCase } = toRefs(props);
</script>
