// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InvoiceShort } from './InvoiceShort';
import {
    InvoiceShortFromJSON,
    InvoiceShortFromJSONTyped,
    InvoiceShortToJSON,
} from './InvoiceShort';
import type { Scenario } from './Scenario';
import {
    ScenarioFromJSON,
    ScenarioFromJSONTyped,
    ScenarioToJSON,
} from './Scenario';

/**
 * 
 * @export
 * @interface PaymentDataResponse
 */
export interface PaymentDataResponse {
    /**
     * 
     * @type {InvoiceShort}
     * @memberof PaymentDataResponse
     */
    invoice: InvoiceShort;
    /**
     * 
     * @type {Scenario}
     * @memberof PaymentDataResponse
     */
    paymentScenario: Scenario;
}

/**
 * Check if a given object implements the PaymentDataResponse interface.
 */
export function instanceOfPaymentDataResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "invoice" in value;
    isInstance = isInstance && "paymentScenario" in value;

    return isInstance;
}

export function PaymentDataResponseFromJSON(json: any): PaymentDataResponse {
    return PaymentDataResponseFromJSONTyped(json, false);
}

export function PaymentDataResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentDataResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'invoice': InvoiceShortFromJSON(json['invoice']),
        'paymentScenario': ScenarioFromJSON(json['payment_scenario']),
    };
}

export function PaymentDataResponseToJSON(value?: PaymentDataResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'invoice': InvoiceShortToJSON(value.invoice),
        'payment_scenario': ScenarioToJSON(value.paymentScenario),
    };
}

