<template>
  <div
    :class="$style['case-price']"
    data-role="case-price"
  >
    <slot />
  </div>
</template>

<style lang="scss" module>
.case-price {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  border-radius: $br10;
  font-size: 0.875rem;
  color: var(--general-white);
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  background-color: var(--button-tonal-default);
  transition: 0.25s;
}
</style>
