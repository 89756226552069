<template>
  <div :class="['slide', $style.first_slide]">
    <div :class="$style['banner-image']" />
    <Title>{{ t("home.banner.bullpass_autumn") }}</Title>
    <Content>{{ t("home.banner.bullpass_autumn_text") }} </Content>
    <Controller to="/event">{{ t("home.banner.to_tasks") }}</Controller>
  </div>
</template>

<script lang="ts" setup>
import Title from "~/components/Home/Intro/Banner/Title.vue";
import Content from "~/components/Home/Intro/Banner/Content.vue";
import Controller from "~/components/Home/Intro/Banner/Controller.vue";

const { t } = useI18n();
</script>

<style lang="scss" module>
.first_slide {
  background-image: linear-gradient(
      101deg,
      #333276 8.4%,
      #40599a 40.17%,
      #5970c2 72.12%,
      #769be3 92.4%,
      #87beff 99.16%
    ),
    radial-gradient(207.85% 127.4% at 7.8% 119.82%, #d45534 0%, #120f22 100%);

  background-size: contain;
  background-position: center !important;

  @media screen and (max-width: 834px) {
    background-image: linear-gradient(
        101deg,
        #333276 8.4%,
        #40599a 40.17%,
        #5970c2 72.12%,
        #769be3 92.4%,
        #87beff 99.16%
      ),
      radial-gradient(
        110.18% 109.16% at 8.71% 108.69%,
        #d45534 0%,
        #120f22 100%
      );
    .text {
      max-width: 400px;
    }
  }
  @media screen and (max-width: 600px) {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.15);
    }
  }
  @media screen and (max-width: 475px) {
    background: linear-gradient(
        101deg,
        #333276 8.4%,
        #40599a 40.17%,
        #5970c2 72.12%,
        #769be3 92.4%,
        #87beff 99.16%
      ),
      radial-gradient(
        115.71% 109.16% at 8.71% 108.69%,
        #d45534 0%,
        #120f22 100%
      );
    background-position-x: 0 !important;
    background-size: cover !important;

    .text {
      max-width: 260px;
    }
  }

  .banner-image {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background-image: url("/banners/second/large.webp");
    background-size: cover;
    width: 100%;
    background-repeat: no-repeat;
    background-position: right bottom !important;

    @media screen and (max-width: 930px) {
      background-image: url("/banners/second/small.webp");
      background-position: 20% bottom !important;
    }
  }
}
</style>
