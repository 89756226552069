import { default as about_45ushQgku2yR5aMeta } from "/app/pages/[[platform]]/about-us.vue?macro=true";
import { default as redirecte104672q2xMeta } from "/app/pages/[[platform]]/auth/[social]/redirect.vue?macro=true";
import { default as top_45upwcfWxx960VMeta } from "/app/pages/[[platform]]/balance/top-up.vue?macro=true";
import { default as withdrawalfKoPjT60TyMeta } from "/app/pages/[[platform]]/balance/withdrawal.vue?macro=true";
import { default as balanceDq00rLaUc6Meta } from "/app/pages/[[platform]]/balance.vue?macro=true";
import { default as boss_45battle5G2DXFw88cMeta } from "/app/pages/[[platform]]/boss-battle.vue?macro=true";
import { default as openPy4rWrbOWFMeta } from "/app/pages/[[platform]]/case/open.vue?macro=true";
import { default as change_45emailK7n0JjoxIGMeta } from "/app/pages/[[platform]]/change-email.vue?macro=true";
import { default as confirm_45emailJsWmJaSUd3Meta } from "/app/pages/[[platform]]/confirm-email.vue?macro=true";
import { default as contactsiFuOoP4p2pMeta } from "/app/pages/[[platform]]/contacts.vue?macro=true";
import { default as elfs_45factorysjOSXVH6tedMeta } from "/app/pages/[[platform]]/elfs-factorys.vue?macro=true";
import { default as eventAY93nfATgrMeta } from "/app/pages/[[platform]]/event.vue?macro=true";
import { default as _91_91slug_93_93prwt7MvrJnMeta } from "/app/pages/[[platform]]/faq/[[slug]].vue?macro=true";
import { default as EmptyYTbgWIs7C0Meta } from "/app/pages/[[platform]]/faq/Empty.vue?macro=true";
import { default as privacyXl4hsUpULuMeta } from "/app/pages/[[platform]]/faq/privacy.vue?macro=true";
import { default as supportvbU49RGOfnMeta } from "/app/pages/[[platform]]/faq/support.vue?macro=true";
import { default as SocialLinks13SvrSuyA0Meta } from "/app/pages/[[platform]]/faq/SupportChat/SocialLinks.vue?macro=true";
import { default as WorkTimePopupqaw6etSUyMMeta } from "/app/pages/[[platform]]/faq/SupportChat/WorkTimePopup.vue?macro=true";
import { default as terms59gxAASX0mMeta } from "/app/pages/[[platform]]/faq/terms.vue?macro=true";
import { default as faqsj7ciw0YgXMeta } from "/app/pages/[[platform]]/faq.vue?macro=true";
import { default as contractsqrwqa1I69xMeta } from "/app/pages/[[platform]]/games/contracts.vue?macro=true";
import { default as crashO26AuxQSDsMeta } from "/app/pages/[[platform]]/games/crash.vue?macro=true";
import { default as ladderCWtQxpZ61LMeta } from "/app/pages/[[platform]]/games/ladder.vue?macro=true";
import { default as rouletteVskhkjC3lqMeta } from "/app/pages/[[platform]]/games/roulette.vue?macro=true";
import { default as sapper8yivmowz6BMeta } from "/app/pages/[[platform]]/games/sapper.vue?macro=true";
import { default as TowerrpoQcIKtY7Meta } from "/app/pages/[[platform]]/games/Tower.vue?macro=true";
import { default as upgrade1s16LPOC6xMeta } from "/app/pages/[[platform]]/games/upgrade.vue?macro=true";
import { default as wheelHGSJ5IWFPbMeta } from "/app/pages/[[platform]]/games/wheel.vue?macro=true";
import { default as gamesxdbZbWonz3Meta } from "/app/pages/[[platform]]/games.vue?macro=true";
import { default as indexAcYp4RF7TdMeta } from "/app/pages/[[platform]]/index.vue?macro=true";
import { default as merlinsrt358VFfoMMeta } from "/app/pages/[[platform]]/merlins.vue?macro=true";
import { default as mini_45gamessi2eqZjDMRMeta } from "/app/pages/[[platform]]/mini-games.vue?macro=true";
import { default as privacy_45policy4k55bZFp6dMeta } from "/app/pages/[[platform]]/privacy-policy.vue?macro=true";
import { default as profile3KjEUuzfn2Meta } from "/app/pages/[[platform]]/profile.vue?macro=true";
import { default as referral_45landWYUWOXMhX1Meta } from "/app/pages/[[platform]]/referral-land.vue?macro=true";
import { default as referralwUTIYbnEEfMeta } from "/app/pages/[[platform]]/referral.vue?macro=true";
import { default as skibidisssezle1F5SsZMeta } from "/app/pages/[[platform]]/skibidisss.vue?macro=true";
import { default as terms_45and_45conditions8ORfbpaGpxMeta } from "/app/pages/[[platform]]/terms-and-conditions.vue?macro=true";
import { default as user_45settingsZ3Hy4lzZK1Meta } from "/app/pages/[[platform]]/user-settings.vue?macro=true";
export default [
  {
    name: about_45ushQgku2yR5aMeta?.name ?? "platform-about-us___ru",
    path: about_45ushQgku2yR5aMeta?.path ?? "/:platform?/about-us",
    meta: about_45ushQgku2yR5aMeta || {},
    alias: about_45ushQgku2yR5aMeta?.alias || [],
    redirect: about_45ushQgku2yR5aMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/about-us.vue").then(m => m.default || m)
  },
  {
    name: about_45ushQgku2yR5aMeta?.name ?? "platform-about-us___en",
    path: about_45ushQgku2yR5aMeta?.path ?? "/en/:platform?/about-us",
    meta: about_45ushQgku2yR5aMeta || {},
    alias: about_45ushQgku2yR5aMeta?.alias || [],
    redirect: about_45ushQgku2yR5aMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/about-us.vue").then(m => m.default || m)
  },
  {
    name: about_45ushQgku2yR5aMeta?.name ?? "platform-about-us___uz",
    path: about_45ushQgku2yR5aMeta?.path ?? "/uz/:platform?/about-us",
    meta: about_45ushQgku2yR5aMeta || {},
    alias: about_45ushQgku2yR5aMeta?.alias || [],
    redirect: about_45ushQgku2yR5aMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/about-us.vue").then(m => m.default || m)
  },
  {
    name: redirecte104672q2xMeta?.name ?? "platform-auth-social-redirect___ru",
    path: redirecte104672q2xMeta?.path ?? "/:platform?/auth/:social()/redirect",
    meta: redirecte104672q2xMeta || {},
    alias: redirecte104672q2xMeta?.alias || [],
    redirect: redirecte104672q2xMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/auth/[social]/redirect.vue").then(m => m.default || m)
  },
  {
    name: redirecte104672q2xMeta?.name ?? "platform-auth-social-redirect___en",
    path: redirecte104672q2xMeta?.path ?? "/en/:platform?/auth/:social()/redirect",
    meta: redirecte104672q2xMeta || {},
    alias: redirecte104672q2xMeta?.alias || [],
    redirect: redirecte104672q2xMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/auth/[social]/redirect.vue").then(m => m.default || m)
  },
  {
    name: redirecte104672q2xMeta?.name ?? "platform-auth-social-redirect___uz",
    path: redirecte104672q2xMeta?.path ?? "/uz/:platform?/auth/:social()/redirect",
    meta: redirecte104672q2xMeta || {},
    alias: redirecte104672q2xMeta?.alias || [],
    redirect: redirecte104672q2xMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/auth/[social]/redirect.vue").then(m => m.default || m)
  },
  {
    name: balanceDq00rLaUc6Meta?.name ?? "platform-balance___ru",
    path: balanceDq00rLaUc6Meta?.path ?? "/:platform?/balance",
    meta: balanceDq00rLaUc6Meta || {},
    alias: balanceDq00rLaUc6Meta?.alias || [],
    redirect: balanceDq00rLaUc6Meta?.redirect,
    component: () => import("/app/pages/[[platform]]/balance.vue").then(m => m.default || m),
    children: [
  {
    name: top_45upwcfWxx960VMeta?.name ?? "platform-balance-top-up___ru",
    path: top_45upwcfWxx960VMeta?.path ?? "top-up",
    meta: top_45upwcfWxx960VMeta || {},
    alias: top_45upwcfWxx960VMeta?.alias || [],
    redirect: top_45upwcfWxx960VMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/balance/top-up.vue").then(m => m.default || m)
  },
  {
    name: withdrawalfKoPjT60TyMeta?.name ?? "platform-balance-withdrawal___ru",
    path: withdrawalfKoPjT60TyMeta?.path ?? "withdrawal",
    meta: withdrawalfKoPjT60TyMeta || {},
    alias: withdrawalfKoPjT60TyMeta?.alias || [],
    redirect: withdrawalfKoPjT60TyMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/balance/withdrawal.vue").then(m => m.default || m)
  }
]
  },
  {
    name: balanceDq00rLaUc6Meta?.name ?? "platform-balance___en",
    path: balanceDq00rLaUc6Meta?.path ?? "/en/:platform?/balance",
    meta: balanceDq00rLaUc6Meta || {},
    alias: balanceDq00rLaUc6Meta?.alias || [],
    redirect: balanceDq00rLaUc6Meta?.redirect,
    component: () => import("/app/pages/[[platform]]/balance.vue").then(m => m.default || m),
    children: [
  {
    name: top_45upwcfWxx960VMeta?.name ?? "platform-balance-top-up___en",
    path: top_45upwcfWxx960VMeta?.path ?? "top-up",
    meta: top_45upwcfWxx960VMeta || {},
    alias: top_45upwcfWxx960VMeta?.alias || [],
    redirect: top_45upwcfWxx960VMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/balance/top-up.vue").then(m => m.default || m)
  },
  {
    name: withdrawalfKoPjT60TyMeta?.name ?? "platform-balance-withdrawal___en",
    path: withdrawalfKoPjT60TyMeta?.path ?? "withdrawal",
    meta: withdrawalfKoPjT60TyMeta || {},
    alias: withdrawalfKoPjT60TyMeta?.alias || [],
    redirect: withdrawalfKoPjT60TyMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/balance/withdrawal.vue").then(m => m.default || m)
  }
]
  },
  {
    name: balanceDq00rLaUc6Meta?.name ?? "platform-balance___uz",
    path: balanceDq00rLaUc6Meta?.path ?? "/uz/:platform?/balance",
    meta: balanceDq00rLaUc6Meta || {},
    alias: balanceDq00rLaUc6Meta?.alias || [],
    redirect: balanceDq00rLaUc6Meta?.redirect,
    component: () => import("/app/pages/[[platform]]/balance.vue").then(m => m.default || m),
    children: [
  {
    name: top_45upwcfWxx960VMeta?.name ?? "platform-balance-top-up___uz",
    path: top_45upwcfWxx960VMeta?.path ?? "top-up",
    meta: top_45upwcfWxx960VMeta || {},
    alias: top_45upwcfWxx960VMeta?.alias || [],
    redirect: top_45upwcfWxx960VMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/balance/top-up.vue").then(m => m.default || m)
  },
  {
    name: withdrawalfKoPjT60TyMeta?.name ?? "platform-balance-withdrawal___uz",
    path: withdrawalfKoPjT60TyMeta?.path ?? "withdrawal",
    meta: withdrawalfKoPjT60TyMeta || {},
    alias: withdrawalfKoPjT60TyMeta?.alias || [],
    redirect: withdrawalfKoPjT60TyMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/balance/withdrawal.vue").then(m => m.default || m)
  }
]
  },
  {
    name: boss_45battle5G2DXFw88cMeta?.name ?? "platform-boss-battle___ru",
    path: boss_45battle5G2DXFw88cMeta?.path ?? "/:platform?/boss-battle",
    meta: boss_45battle5G2DXFw88cMeta || {},
    alias: boss_45battle5G2DXFw88cMeta?.alias || [],
    redirect: boss_45battle5G2DXFw88cMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/boss-battle.vue").then(m => m.default || m)
  },
  {
    name: boss_45battle5G2DXFw88cMeta?.name ?? "platform-boss-battle___en",
    path: boss_45battle5G2DXFw88cMeta?.path ?? "/en/:platform?/boss-battle",
    meta: boss_45battle5G2DXFw88cMeta || {},
    alias: boss_45battle5G2DXFw88cMeta?.alias || [],
    redirect: boss_45battle5G2DXFw88cMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/boss-battle.vue").then(m => m.default || m)
  },
  {
    name: boss_45battle5G2DXFw88cMeta?.name ?? "platform-boss-battle___uz",
    path: boss_45battle5G2DXFw88cMeta?.path ?? "/uz/:platform?/boss-battle",
    meta: boss_45battle5G2DXFw88cMeta || {},
    alias: boss_45battle5G2DXFw88cMeta?.alias || [],
    redirect: boss_45battle5G2DXFw88cMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/boss-battle.vue").then(m => m.default || m)
  },
  {
    name: openPy4rWrbOWFMeta?.name ?? "platform-case-open___ru",
    path: openPy4rWrbOWFMeta?.path ?? "/:platform?/case/open",
    meta: openPy4rWrbOWFMeta || {},
    alias: openPy4rWrbOWFMeta?.alias || [],
    redirect: openPy4rWrbOWFMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/case/open.vue").then(m => m.default || m)
  },
  {
    name: openPy4rWrbOWFMeta?.name ?? "platform-case-open___en",
    path: openPy4rWrbOWFMeta?.path ?? "/en/:platform?/case/open",
    meta: openPy4rWrbOWFMeta || {},
    alias: openPy4rWrbOWFMeta?.alias || [],
    redirect: openPy4rWrbOWFMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/case/open.vue").then(m => m.default || m)
  },
  {
    name: openPy4rWrbOWFMeta?.name ?? "platform-case-open___uz",
    path: openPy4rWrbOWFMeta?.path ?? "/uz/:platform?/case/open",
    meta: openPy4rWrbOWFMeta || {},
    alias: openPy4rWrbOWFMeta?.alias || [],
    redirect: openPy4rWrbOWFMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/case/open.vue").then(m => m.default || m)
  },
  {
    name: change_45emailK7n0JjoxIGMeta?.name ?? "platform-change-email___ru",
    path: change_45emailK7n0JjoxIGMeta?.path ?? "/:platform?/change-email",
    meta: change_45emailK7n0JjoxIGMeta || {},
    alias: change_45emailK7n0JjoxIGMeta?.alias || [],
    redirect: change_45emailK7n0JjoxIGMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/change-email.vue").then(m => m.default || m)
  },
  {
    name: change_45emailK7n0JjoxIGMeta?.name ?? "platform-change-email___en",
    path: change_45emailK7n0JjoxIGMeta?.path ?? "/en/:platform?/change-email",
    meta: change_45emailK7n0JjoxIGMeta || {},
    alias: change_45emailK7n0JjoxIGMeta?.alias || [],
    redirect: change_45emailK7n0JjoxIGMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/change-email.vue").then(m => m.default || m)
  },
  {
    name: change_45emailK7n0JjoxIGMeta?.name ?? "platform-change-email___uz",
    path: change_45emailK7n0JjoxIGMeta?.path ?? "/uz/:platform?/change-email",
    meta: change_45emailK7n0JjoxIGMeta || {},
    alias: change_45emailK7n0JjoxIGMeta?.alias || [],
    redirect: change_45emailK7n0JjoxIGMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/change-email.vue").then(m => m.default || m)
  },
  {
    name: confirm_45emailJsWmJaSUd3Meta?.name ?? "platform-confirm-email___ru",
    path: confirm_45emailJsWmJaSUd3Meta?.path ?? "/:platform?/confirm-email",
    meta: confirm_45emailJsWmJaSUd3Meta || {},
    alias: confirm_45emailJsWmJaSUd3Meta?.alias || [],
    redirect: confirm_45emailJsWmJaSUd3Meta?.redirect,
    component: () => import("/app/pages/[[platform]]/confirm-email.vue").then(m => m.default || m)
  },
  {
    name: confirm_45emailJsWmJaSUd3Meta?.name ?? "platform-confirm-email___en",
    path: confirm_45emailJsWmJaSUd3Meta?.path ?? "/en/:platform?/confirm-email",
    meta: confirm_45emailJsWmJaSUd3Meta || {},
    alias: confirm_45emailJsWmJaSUd3Meta?.alias || [],
    redirect: confirm_45emailJsWmJaSUd3Meta?.redirect,
    component: () => import("/app/pages/[[platform]]/confirm-email.vue").then(m => m.default || m)
  },
  {
    name: confirm_45emailJsWmJaSUd3Meta?.name ?? "platform-confirm-email___uz",
    path: confirm_45emailJsWmJaSUd3Meta?.path ?? "/uz/:platform?/confirm-email",
    meta: confirm_45emailJsWmJaSUd3Meta || {},
    alias: confirm_45emailJsWmJaSUd3Meta?.alias || [],
    redirect: confirm_45emailJsWmJaSUd3Meta?.redirect,
    component: () => import("/app/pages/[[platform]]/confirm-email.vue").then(m => m.default || m)
  },
  {
    name: contactsiFuOoP4p2pMeta?.name ?? "platform-contacts___ru",
    path: contactsiFuOoP4p2pMeta?.path ?? "/:platform?/contacts",
    meta: contactsiFuOoP4p2pMeta || {},
    alias: contactsiFuOoP4p2pMeta?.alias || [],
    redirect: contactsiFuOoP4p2pMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/contacts.vue").then(m => m.default || m)
  },
  {
    name: contactsiFuOoP4p2pMeta?.name ?? "platform-contacts___en",
    path: contactsiFuOoP4p2pMeta?.path ?? "/en/:platform?/contacts",
    meta: contactsiFuOoP4p2pMeta || {},
    alias: contactsiFuOoP4p2pMeta?.alias || [],
    redirect: contactsiFuOoP4p2pMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/contacts.vue").then(m => m.default || m)
  },
  {
    name: contactsiFuOoP4p2pMeta?.name ?? "platform-contacts___uz",
    path: contactsiFuOoP4p2pMeta?.path ?? "/uz/:platform?/contacts",
    meta: contactsiFuOoP4p2pMeta || {},
    alias: contactsiFuOoP4p2pMeta?.alias || [],
    redirect: contactsiFuOoP4p2pMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/contacts.vue").then(m => m.default || m)
  },
  {
    name: elfs_45factorysjOSXVH6tedMeta?.name ?? "platform-elfs-factorys___ru",
    path: elfs_45factorysjOSXVH6tedMeta?.path ?? "/:platform?/elfs-factorys",
    meta: elfs_45factorysjOSXVH6tedMeta || {},
    alias: elfs_45factorysjOSXVH6tedMeta?.alias || [],
    redirect: elfs_45factorysjOSXVH6tedMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/elfs-factorys.vue").then(m => m.default || m)
  },
  {
    name: elfs_45factorysjOSXVH6tedMeta?.name ?? "platform-elfs-factorys___en",
    path: elfs_45factorysjOSXVH6tedMeta?.path ?? "/en/:platform?/elfs-factorys",
    meta: elfs_45factorysjOSXVH6tedMeta || {},
    alias: elfs_45factorysjOSXVH6tedMeta?.alias || [],
    redirect: elfs_45factorysjOSXVH6tedMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/elfs-factorys.vue").then(m => m.default || m)
  },
  {
    name: elfs_45factorysjOSXVH6tedMeta?.name ?? "platform-elfs-factorys___uz",
    path: elfs_45factorysjOSXVH6tedMeta?.path ?? "/uz/:platform?/elfs-factorys",
    meta: elfs_45factorysjOSXVH6tedMeta || {},
    alias: elfs_45factorysjOSXVH6tedMeta?.alias || [],
    redirect: elfs_45factorysjOSXVH6tedMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/elfs-factorys.vue").then(m => m.default || m)
  },
  {
    name: eventAY93nfATgrMeta?.name ?? "platform-event___ru",
    path: eventAY93nfATgrMeta?.path ?? "/:platform?/event",
    meta: eventAY93nfATgrMeta || {},
    alias: eventAY93nfATgrMeta?.alias || [],
    redirect: eventAY93nfATgrMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/event.vue").then(m => m.default || m)
  },
  {
    name: eventAY93nfATgrMeta?.name ?? "platform-event___en",
    path: eventAY93nfATgrMeta?.path ?? "/en/:platform?/event",
    meta: eventAY93nfATgrMeta || {},
    alias: eventAY93nfATgrMeta?.alias || [],
    redirect: eventAY93nfATgrMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/event.vue").then(m => m.default || m)
  },
  {
    name: eventAY93nfATgrMeta?.name ?? "platform-event___uz",
    path: eventAY93nfATgrMeta?.path ?? "/uz/:platform?/event",
    meta: eventAY93nfATgrMeta || {},
    alias: eventAY93nfATgrMeta?.alias || [],
    redirect: eventAY93nfATgrMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/event.vue").then(m => m.default || m)
  },
  {
    name: faqsj7ciw0YgXMeta?.name ?? "platform-faq___ru",
    path: faqsj7ciw0YgXMeta?.path ?? "/:platform?/faq",
    meta: faqsj7ciw0YgXMeta || {},
    alias: faqsj7ciw0YgXMeta?.alias || [],
    redirect: faqsj7ciw0YgXMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/faq.vue").then(m => m.default || m),
    children: [
  {
    name: _91_91slug_93_93prwt7MvrJnMeta?.name ?? "platform-faq-slug___ru",
    path: _91_91slug_93_93prwt7MvrJnMeta?.path ?? ":slug?",
    meta: _91_91slug_93_93prwt7MvrJnMeta || {},
    alias: _91_91slug_93_93prwt7MvrJnMeta?.alias || [],
    redirect: _91_91slug_93_93prwt7MvrJnMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/faq/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: EmptyYTbgWIs7C0Meta?.name ?? "platform-faq-Empty___ru",
    path: EmptyYTbgWIs7C0Meta?.path ?? "Empty",
    meta: EmptyYTbgWIs7C0Meta || {},
    alias: EmptyYTbgWIs7C0Meta?.alias || [],
    redirect: EmptyYTbgWIs7C0Meta?.redirect,
    component: () => import("/app/pages/[[platform]]/faq/Empty.vue").then(m => m.default || m)
  },
  {
    name: privacyXl4hsUpULuMeta?.name ?? "platform-faq-privacy___ru",
    path: privacyXl4hsUpULuMeta?.path ?? "privacy",
    meta: privacyXl4hsUpULuMeta || {},
    alias: privacyXl4hsUpULuMeta?.alias || [],
    redirect: privacyXl4hsUpULuMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/faq/privacy.vue").then(m => m.default || m)
  },
  {
    name: supportvbU49RGOfnMeta?.name ?? "platform-faq-support___ru",
    path: supportvbU49RGOfnMeta?.path ?? "support",
    meta: supportvbU49RGOfnMeta || {},
    alias: supportvbU49RGOfnMeta?.alias || [],
    redirect: supportvbU49RGOfnMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/faq/support.vue").then(m => m.default || m)
  },
  {
    name: SocialLinks13SvrSuyA0Meta?.name ?? "platform-faq-SupportChat-SocialLinks___ru",
    path: SocialLinks13SvrSuyA0Meta?.path ?? "SupportChat/SocialLinks",
    meta: SocialLinks13SvrSuyA0Meta || {},
    alias: SocialLinks13SvrSuyA0Meta?.alias || [],
    redirect: SocialLinks13SvrSuyA0Meta?.redirect,
    component: () => import("/app/pages/[[platform]]/faq/SupportChat/SocialLinks.vue").then(m => m.default || m)
  },
  {
    name: WorkTimePopupqaw6etSUyMMeta?.name ?? "platform-faq-SupportChat-WorkTimePopup___ru",
    path: WorkTimePopupqaw6etSUyMMeta?.path ?? "SupportChat/WorkTimePopup",
    meta: WorkTimePopupqaw6etSUyMMeta || {},
    alias: WorkTimePopupqaw6etSUyMMeta?.alias || [],
    redirect: WorkTimePopupqaw6etSUyMMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/faq/SupportChat/WorkTimePopup.vue").then(m => m.default || m)
  },
  {
    name: terms59gxAASX0mMeta?.name ?? "platform-faq-terms___ru",
    path: terms59gxAASX0mMeta?.path ?? "terms",
    meta: terms59gxAASX0mMeta || {},
    alias: terms59gxAASX0mMeta?.alias || [],
    redirect: terms59gxAASX0mMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/faq/terms.vue").then(m => m.default || m)
  }
]
  },
  {
    name: faqsj7ciw0YgXMeta?.name ?? "platform-faq___en",
    path: faqsj7ciw0YgXMeta?.path ?? "/en/:platform?/faq",
    meta: faqsj7ciw0YgXMeta || {},
    alias: faqsj7ciw0YgXMeta?.alias || [],
    redirect: faqsj7ciw0YgXMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/faq.vue").then(m => m.default || m),
    children: [
  {
    name: _91_91slug_93_93prwt7MvrJnMeta?.name ?? "platform-faq-slug___en",
    path: _91_91slug_93_93prwt7MvrJnMeta?.path ?? ":slug?",
    meta: _91_91slug_93_93prwt7MvrJnMeta || {},
    alias: _91_91slug_93_93prwt7MvrJnMeta?.alias || [],
    redirect: _91_91slug_93_93prwt7MvrJnMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/faq/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: EmptyYTbgWIs7C0Meta?.name ?? "platform-faq-Empty___en",
    path: EmptyYTbgWIs7C0Meta?.path ?? "Empty",
    meta: EmptyYTbgWIs7C0Meta || {},
    alias: EmptyYTbgWIs7C0Meta?.alias || [],
    redirect: EmptyYTbgWIs7C0Meta?.redirect,
    component: () => import("/app/pages/[[platform]]/faq/Empty.vue").then(m => m.default || m)
  },
  {
    name: privacyXl4hsUpULuMeta?.name ?? "platform-faq-privacy___en",
    path: privacyXl4hsUpULuMeta?.path ?? "privacy",
    meta: privacyXl4hsUpULuMeta || {},
    alias: privacyXl4hsUpULuMeta?.alias || [],
    redirect: privacyXl4hsUpULuMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/faq/privacy.vue").then(m => m.default || m)
  },
  {
    name: supportvbU49RGOfnMeta?.name ?? "platform-faq-support___en",
    path: supportvbU49RGOfnMeta?.path ?? "support",
    meta: supportvbU49RGOfnMeta || {},
    alias: supportvbU49RGOfnMeta?.alias || [],
    redirect: supportvbU49RGOfnMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/faq/support.vue").then(m => m.default || m)
  },
  {
    name: SocialLinks13SvrSuyA0Meta?.name ?? "platform-faq-SupportChat-SocialLinks___en",
    path: SocialLinks13SvrSuyA0Meta?.path ?? "SupportChat/SocialLinks",
    meta: SocialLinks13SvrSuyA0Meta || {},
    alias: SocialLinks13SvrSuyA0Meta?.alias || [],
    redirect: SocialLinks13SvrSuyA0Meta?.redirect,
    component: () => import("/app/pages/[[platform]]/faq/SupportChat/SocialLinks.vue").then(m => m.default || m)
  },
  {
    name: WorkTimePopupqaw6etSUyMMeta?.name ?? "platform-faq-SupportChat-WorkTimePopup___en",
    path: WorkTimePopupqaw6etSUyMMeta?.path ?? "SupportChat/WorkTimePopup",
    meta: WorkTimePopupqaw6etSUyMMeta || {},
    alias: WorkTimePopupqaw6etSUyMMeta?.alias || [],
    redirect: WorkTimePopupqaw6etSUyMMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/faq/SupportChat/WorkTimePopup.vue").then(m => m.default || m)
  },
  {
    name: terms59gxAASX0mMeta?.name ?? "platform-faq-terms___en",
    path: terms59gxAASX0mMeta?.path ?? "terms",
    meta: terms59gxAASX0mMeta || {},
    alias: terms59gxAASX0mMeta?.alias || [],
    redirect: terms59gxAASX0mMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/faq/terms.vue").then(m => m.default || m)
  }
]
  },
  {
    name: faqsj7ciw0YgXMeta?.name ?? "platform-faq___uz",
    path: faqsj7ciw0YgXMeta?.path ?? "/uz/:platform?/faq",
    meta: faqsj7ciw0YgXMeta || {},
    alias: faqsj7ciw0YgXMeta?.alias || [],
    redirect: faqsj7ciw0YgXMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/faq.vue").then(m => m.default || m),
    children: [
  {
    name: _91_91slug_93_93prwt7MvrJnMeta?.name ?? "platform-faq-slug___uz",
    path: _91_91slug_93_93prwt7MvrJnMeta?.path ?? ":slug?",
    meta: _91_91slug_93_93prwt7MvrJnMeta || {},
    alias: _91_91slug_93_93prwt7MvrJnMeta?.alias || [],
    redirect: _91_91slug_93_93prwt7MvrJnMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/faq/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: EmptyYTbgWIs7C0Meta?.name ?? "platform-faq-Empty___uz",
    path: EmptyYTbgWIs7C0Meta?.path ?? "Empty",
    meta: EmptyYTbgWIs7C0Meta || {},
    alias: EmptyYTbgWIs7C0Meta?.alias || [],
    redirect: EmptyYTbgWIs7C0Meta?.redirect,
    component: () => import("/app/pages/[[platform]]/faq/Empty.vue").then(m => m.default || m)
  },
  {
    name: privacyXl4hsUpULuMeta?.name ?? "platform-faq-privacy___uz",
    path: privacyXl4hsUpULuMeta?.path ?? "privacy",
    meta: privacyXl4hsUpULuMeta || {},
    alias: privacyXl4hsUpULuMeta?.alias || [],
    redirect: privacyXl4hsUpULuMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/faq/privacy.vue").then(m => m.default || m)
  },
  {
    name: supportvbU49RGOfnMeta?.name ?? "platform-faq-support___uz",
    path: supportvbU49RGOfnMeta?.path ?? "support",
    meta: supportvbU49RGOfnMeta || {},
    alias: supportvbU49RGOfnMeta?.alias || [],
    redirect: supportvbU49RGOfnMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/faq/support.vue").then(m => m.default || m)
  },
  {
    name: SocialLinks13SvrSuyA0Meta?.name ?? "platform-faq-SupportChat-SocialLinks___uz",
    path: SocialLinks13SvrSuyA0Meta?.path ?? "SupportChat/SocialLinks",
    meta: SocialLinks13SvrSuyA0Meta || {},
    alias: SocialLinks13SvrSuyA0Meta?.alias || [],
    redirect: SocialLinks13SvrSuyA0Meta?.redirect,
    component: () => import("/app/pages/[[platform]]/faq/SupportChat/SocialLinks.vue").then(m => m.default || m)
  },
  {
    name: WorkTimePopupqaw6etSUyMMeta?.name ?? "platform-faq-SupportChat-WorkTimePopup___uz",
    path: WorkTimePopupqaw6etSUyMMeta?.path ?? "SupportChat/WorkTimePopup",
    meta: WorkTimePopupqaw6etSUyMMeta || {},
    alias: WorkTimePopupqaw6etSUyMMeta?.alias || [],
    redirect: WorkTimePopupqaw6etSUyMMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/faq/SupportChat/WorkTimePopup.vue").then(m => m.default || m)
  },
  {
    name: terms59gxAASX0mMeta?.name ?? "platform-faq-terms___uz",
    path: terms59gxAASX0mMeta?.path ?? "terms",
    meta: terms59gxAASX0mMeta || {},
    alias: terms59gxAASX0mMeta?.alias || [],
    redirect: terms59gxAASX0mMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/faq/terms.vue").then(m => m.default || m)
  }
]
  },
  {
    name: gamesxdbZbWonz3Meta?.name ?? "platform-games___ru",
    path: gamesxdbZbWonz3Meta?.path ?? "/:platform?/games",
    meta: gamesxdbZbWonz3Meta || {},
    alias: gamesxdbZbWonz3Meta?.alias || [],
    redirect: gamesxdbZbWonz3Meta?.redirect,
    component: () => import("/app/pages/[[platform]]/games.vue").then(m => m.default || m),
    children: [
  {
    name: contractsqrwqa1I69xMeta?.name ?? "platform-games-contracts___ru",
    path: contractsqrwqa1I69xMeta?.path ?? "contracts",
    meta: contractsqrwqa1I69xMeta || {},
    alias: contractsqrwqa1I69xMeta?.alias || [],
    redirect: contractsqrwqa1I69xMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/games/contracts.vue").then(m => m.default || m)
  },
  {
    name: crashO26AuxQSDsMeta?.name ?? "platform-games-crash___ru",
    path: crashO26AuxQSDsMeta?.path ?? "crash",
    meta: crashO26AuxQSDsMeta || {},
    alias: crashO26AuxQSDsMeta?.alias || [],
    redirect: crashO26AuxQSDsMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/games/crash.vue").then(m => m.default || m)
  },
  {
    name: ladderCWtQxpZ61LMeta?.name ?? "platform-games-ladder___ru",
    path: ladderCWtQxpZ61LMeta?.path ?? "ladder",
    meta: ladderCWtQxpZ61LMeta || {},
    alias: ladderCWtQxpZ61LMeta?.alias || [],
    redirect: ladderCWtQxpZ61LMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/games/ladder.vue").then(m => m.default || m)
  },
  {
    name: rouletteVskhkjC3lqMeta?.name ?? "platform-games-roulette___ru",
    path: rouletteVskhkjC3lqMeta?.path ?? "roulette",
    meta: rouletteVskhkjC3lqMeta || {},
    alias: rouletteVskhkjC3lqMeta?.alias || [],
    redirect: rouletteVskhkjC3lqMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/games/roulette.vue").then(m => m.default || m)
  },
  {
    name: sapper8yivmowz6BMeta?.name ?? "platform-games-sapper___ru",
    path: sapper8yivmowz6BMeta?.path ?? "sapper",
    meta: sapper8yivmowz6BMeta || {},
    alias: sapper8yivmowz6BMeta?.alias || [],
    redirect: sapper8yivmowz6BMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/games/sapper.vue").then(m => m.default || m)
  },
  {
    name: TowerrpoQcIKtY7Meta?.name ?? "platform-games-Tower___ru",
    path: TowerrpoQcIKtY7Meta?.path ?? "Tower",
    meta: TowerrpoQcIKtY7Meta || {},
    alias: TowerrpoQcIKtY7Meta?.alias || [],
    redirect: TowerrpoQcIKtY7Meta?.redirect,
    component: () => import("/app/pages/[[platform]]/games/Tower.vue").then(m => m.default || m)
  },
  {
    name: upgrade1s16LPOC6xMeta?.name ?? "platform-games-upgrade___ru",
    path: upgrade1s16LPOC6xMeta?.path ?? "upgrade",
    meta: upgrade1s16LPOC6xMeta || {},
    alias: upgrade1s16LPOC6xMeta?.alias || [],
    redirect: upgrade1s16LPOC6xMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/games/upgrade.vue").then(m => m.default || m)
  },
  {
    name: wheelHGSJ5IWFPbMeta?.name ?? "platform-games-wheel___ru",
    path: wheelHGSJ5IWFPbMeta?.path ?? "wheel",
    meta: wheelHGSJ5IWFPbMeta || {},
    alias: wheelHGSJ5IWFPbMeta?.alias || [],
    redirect: wheelHGSJ5IWFPbMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/games/wheel.vue").then(m => m.default || m)
  }
]
  },
  {
    name: gamesxdbZbWonz3Meta?.name ?? "platform-games___en",
    path: gamesxdbZbWonz3Meta?.path ?? "/en/:platform?/games",
    meta: gamesxdbZbWonz3Meta || {},
    alias: gamesxdbZbWonz3Meta?.alias || [],
    redirect: gamesxdbZbWonz3Meta?.redirect,
    component: () => import("/app/pages/[[platform]]/games.vue").then(m => m.default || m),
    children: [
  {
    name: contractsqrwqa1I69xMeta?.name ?? "platform-games-contracts___en",
    path: contractsqrwqa1I69xMeta?.path ?? "contracts",
    meta: contractsqrwqa1I69xMeta || {},
    alias: contractsqrwqa1I69xMeta?.alias || [],
    redirect: contractsqrwqa1I69xMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/games/contracts.vue").then(m => m.default || m)
  },
  {
    name: crashO26AuxQSDsMeta?.name ?? "platform-games-crash___en",
    path: crashO26AuxQSDsMeta?.path ?? "crash",
    meta: crashO26AuxQSDsMeta || {},
    alias: crashO26AuxQSDsMeta?.alias || [],
    redirect: crashO26AuxQSDsMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/games/crash.vue").then(m => m.default || m)
  },
  {
    name: ladderCWtQxpZ61LMeta?.name ?? "platform-games-ladder___en",
    path: ladderCWtQxpZ61LMeta?.path ?? "ladder",
    meta: ladderCWtQxpZ61LMeta || {},
    alias: ladderCWtQxpZ61LMeta?.alias || [],
    redirect: ladderCWtQxpZ61LMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/games/ladder.vue").then(m => m.default || m)
  },
  {
    name: rouletteVskhkjC3lqMeta?.name ?? "platform-games-roulette___en",
    path: rouletteVskhkjC3lqMeta?.path ?? "roulette",
    meta: rouletteVskhkjC3lqMeta || {},
    alias: rouletteVskhkjC3lqMeta?.alias || [],
    redirect: rouletteVskhkjC3lqMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/games/roulette.vue").then(m => m.default || m)
  },
  {
    name: sapper8yivmowz6BMeta?.name ?? "platform-games-sapper___en",
    path: sapper8yivmowz6BMeta?.path ?? "sapper",
    meta: sapper8yivmowz6BMeta || {},
    alias: sapper8yivmowz6BMeta?.alias || [],
    redirect: sapper8yivmowz6BMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/games/sapper.vue").then(m => m.default || m)
  },
  {
    name: TowerrpoQcIKtY7Meta?.name ?? "platform-games-Tower___en",
    path: TowerrpoQcIKtY7Meta?.path ?? "Tower",
    meta: TowerrpoQcIKtY7Meta || {},
    alias: TowerrpoQcIKtY7Meta?.alias || [],
    redirect: TowerrpoQcIKtY7Meta?.redirect,
    component: () => import("/app/pages/[[platform]]/games/Tower.vue").then(m => m.default || m)
  },
  {
    name: upgrade1s16LPOC6xMeta?.name ?? "platform-games-upgrade___en",
    path: upgrade1s16LPOC6xMeta?.path ?? "upgrade",
    meta: upgrade1s16LPOC6xMeta || {},
    alias: upgrade1s16LPOC6xMeta?.alias || [],
    redirect: upgrade1s16LPOC6xMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/games/upgrade.vue").then(m => m.default || m)
  },
  {
    name: wheelHGSJ5IWFPbMeta?.name ?? "platform-games-wheel___en",
    path: wheelHGSJ5IWFPbMeta?.path ?? "wheel",
    meta: wheelHGSJ5IWFPbMeta || {},
    alias: wheelHGSJ5IWFPbMeta?.alias || [],
    redirect: wheelHGSJ5IWFPbMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/games/wheel.vue").then(m => m.default || m)
  }
]
  },
  {
    name: gamesxdbZbWonz3Meta?.name ?? "platform-games___uz",
    path: gamesxdbZbWonz3Meta?.path ?? "/uz/:platform?/games",
    meta: gamesxdbZbWonz3Meta || {},
    alias: gamesxdbZbWonz3Meta?.alias || [],
    redirect: gamesxdbZbWonz3Meta?.redirect,
    component: () => import("/app/pages/[[platform]]/games.vue").then(m => m.default || m),
    children: [
  {
    name: contractsqrwqa1I69xMeta?.name ?? "platform-games-contracts___uz",
    path: contractsqrwqa1I69xMeta?.path ?? "contracts",
    meta: contractsqrwqa1I69xMeta || {},
    alias: contractsqrwqa1I69xMeta?.alias || [],
    redirect: contractsqrwqa1I69xMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/games/contracts.vue").then(m => m.default || m)
  },
  {
    name: crashO26AuxQSDsMeta?.name ?? "platform-games-crash___uz",
    path: crashO26AuxQSDsMeta?.path ?? "crash",
    meta: crashO26AuxQSDsMeta || {},
    alias: crashO26AuxQSDsMeta?.alias || [],
    redirect: crashO26AuxQSDsMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/games/crash.vue").then(m => m.default || m)
  },
  {
    name: ladderCWtQxpZ61LMeta?.name ?? "platform-games-ladder___uz",
    path: ladderCWtQxpZ61LMeta?.path ?? "ladder",
    meta: ladderCWtQxpZ61LMeta || {},
    alias: ladderCWtQxpZ61LMeta?.alias || [],
    redirect: ladderCWtQxpZ61LMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/games/ladder.vue").then(m => m.default || m)
  },
  {
    name: rouletteVskhkjC3lqMeta?.name ?? "platform-games-roulette___uz",
    path: rouletteVskhkjC3lqMeta?.path ?? "roulette",
    meta: rouletteVskhkjC3lqMeta || {},
    alias: rouletteVskhkjC3lqMeta?.alias || [],
    redirect: rouletteVskhkjC3lqMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/games/roulette.vue").then(m => m.default || m)
  },
  {
    name: sapper8yivmowz6BMeta?.name ?? "platform-games-sapper___uz",
    path: sapper8yivmowz6BMeta?.path ?? "sapper",
    meta: sapper8yivmowz6BMeta || {},
    alias: sapper8yivmowz6BMeta?.alias || [],
    redirect: sapper8yivmowz6BMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/games/sapper.vue").then(m => m.default || m)
  },
  {
    name: TowerrpoQcIKtY7Meta?.name ?? "platform-games-Tower___uz",
    path: TowerrpoQcIKtY7Meta?.path ?? "Tower",
    meta: TowerrpoQcIKtY7Meta || {},
    alias: TowerrpoQcIKtY7Meta?.alias || [],
    redirect: TowerrpoQcIKtY7Meta?.redirect,
    component: () => import("/app/pages/[[platform]]/games/Tower.vue").then(m => m.default || m)
  },
  {
    name: upgrade1s16LPOC6xMeta?.name ?? "platform-games-upgrade___uz",
    path: upgrade1s16LPOC6xMeta?.path ?? "upgrade",
    meta: upgrade1s16LPOC6xMeta || {},
    alias: upgrade1s16LPOC6xMeta?.alias || [],
    redirect: upgrade1s16LPOC6xMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/games/upgrade.vue").then(m => m.default || m)
  },
  {
    name: wheelHGSJ5IWFPbMeta?.name ?? "platform-games-wheel___uz",
    path: wheelHGSJ5IWFPbMeta?.path ?? "wheel",
    meta: wheelHGSJ5IWFPbMeta || {},
    alias: wheelHGSJ5IWFPbMeta?.alias || [],
    redirect: wheelHGSJ5IWFPbMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/games/wheel.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexAcYp4RF7TdMeta?.name ?? "platform___ru",
    path: indexAcYp4RF7TdMeta?.path ?? "/:platform?",
    meta: indexAcYp4RF7TdMeta || {},
    alias: indexAcYp4RF7TdMeta?.alias || [],
    redirect: indexAcYp4RF7TdMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/index.vue").then(m => m.default || m)
  },
  {
    name: indexAcYp4RF7TdMeta?.name ?? "platform___en",
    path: indexAcYp4RF7TdMeta?.path ?? "/en/:platform?",
    meta: indexAcYp4RF7TdMeta || {},
    alias: indexAcYp4RF7TdMeta?.alias || [],
    redirect: indexAcYp4RF7TdMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/index.vue").then(m => m.default || m)
  },
  {
    name: indexAcYp4RF7TdMeta?.name ?? "platform___uz",
    path: indexAcYp4RF7TdMeta?.path ?? "/uz/:platform?",
    meta: indexAcYp4RF7TdMeta || {},
    alias: indexAcYp4RF7TdMeta?.alias || [],
    redirect: indexAcYp4RF7TdMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/index.vue").then(m => m.default || m)
  },
  {
    name: merlinsrt358VFfoMMeta?.name ?? "platform-merlins___ru",
    path: merlinsrt358VFfoMMeta?.path ?? "/:platform?/merlins",
    meta: merlinsrt358VFfoMMeta || {},
    alias: merlinsrt358VFfoMMeta?.alias || [],
    redirect: merlinsrt358VFfoMMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/merlins.vue").then(m => m.default || m)
  },
  {
    name: merlinsrt358VFfoMMeta?.name ?? "platform-merlins___en",
    path: merlinsrt358VFfoMMeta?.path ?? "/en/:platform?/merlins",
    meta: merlinsrt358VFfoMMeta || {},
    alias: merlinsrt358VFfoMMeta?.alias || [],
    redirect: merlinsrt358VFfoMMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/merlins.vue").then(m => m.default || m)
  },
  {
    name: merlinsrt358VFfoMMeta?.name ?? "platform-merlins___uz",
    path: merlinsrt358VFfoMMeta?.path ?? "/uz/:platform?/merlins",
    meta: merlinsrt358VFfoMMeta || {},
    alias: merlinsrt358VFfoMMeta?.alias || [],
    redirect: merlinsrt358VFfoMMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/merlins.vue").then(m => m.default || m)
  },
  {
    name: mini_45gamessi2eqZjDMRMeta?.name ?? "platform-mini-games___ru",
    path: mini_45gamessi2eqZjDMRMeta?.path ?? "/:platform?/mini-games",
    meta: mini_45gamessi2eqZjDMRMeta || {},
    alias: mini_45gamessi2eqZjDMRMeta?.alias || [],
    redirect: mini_45gamessi2eqZjDMRMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/mini-games.vue").then(m => m.default || m)
  },
  {
    name: mini_45gamessi2eqZjDMRMeta?.name ?? "platform-mini-games___en",
    path: mini_45gamessi2eqZjDMRMeta?.path ?? "/en/:platform?/mini-games",
    meta: mini_45gamessi2eqZjDMRMeta || {},
    alias: mini_45gamessi2eqZjDMRMeta?.alias || [],
    redirect: mini_45gamessi2eqZjDMRMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/mini-games.vue").then(m => m.default || m)
  },
  {
    name: mini_45gamessi2eqZjDMRMeta?.name ?? "platform-mini-games___uz",
    path: mini_45gamessi2eqZjDMRMeta?.path ?? "/uz/:platform?/mini-games",
    meta: mini_45gamessi2eqZjDMRMeta || {},
    alias: mini_45gamessi2eqZjDMRMeta?.alias || [],
    redirect: mini_45gamessi2eqZjDMRMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/mini-games.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy4k55bZFp6dMeta?.name ?? "platform-privacy-policy___ru",
    path: privacy_45policy4k55bZFp6dMeta?.path ?? "/:platform?/privacy-policy",
    meta: privacy_45policy4k55bZFp6dMeta || {},
    alias: privacy_45policy4k55bZFp6dMeta?.alias || [],
    redirect: privacy_45policy4k55bZFp6dMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy4k55bZFp6dMeta?.name ?? "platform-privacy-policy___en",
    path: privacy_45policy4k55bZFp6dMeta?.path ?? "/en/:platform?/privacy-policy",
    meta: privacy_45policy4k55bZFp6dMeta || {},
    alias: privacy_45policy4k55bZFp6dMeta?.alias || [],
    redirect: privacy_45policy4k55bZFp6dMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy4k55bZFp6dMeta?.name ?? "platform-privacy-policy___uz",
    path: privacy_45policy4k55bZFp6dMeta?.path ?? "/uz/:platform?/privacy-policy",
    meta: privacy_45policy4k55bZFp6dMeta || {},
    alias: privacy_45policy4k55bZFp6dMeta?.alias || [],
    redirect: privacy_45policy4k55bZFp6dMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: profile3KjEUuzfn2Meta?.name ?? "platform-profile___ru",
    path: profile3KjEUuzfn2Meta?.path ?? "/:platform?/profile",
    meta: profile3KjEUuzfn2Meta || {},
    alias: profile3KjEUuzfn2Meta?.alias || [],
    redirect: profile3KjEUuzfn2Meta?.redirect,
    component: () => import("/app/pages/[[platform]]/profile.vue").then(m => m.default || m)
  },
  {
    name: profile3KjEUuzfn2Meta?.name ?? "platform-profile___en",
    path: profile3KjEUuzfn2Meta?.path ?? "/en/:platform?/profile",
    meta: profile3KjEUuzfn2Meta || {},
    alias: profile3KjEUuzfn2Meta?.alias || [],
    redirect: profile3KjEUuzfn2Meta?.redirect,
    component: () => import("/app/pages/[[platform]]/profile.vue").then(m => m.default || m)
  },
  {
    name: profile3KjEUuzfn2Meta?.name ?? "platform-profile___uz",
    path: profile3KjEUuzfn2Meta?.path ?? "/uz/:platform?/profile",
    meta: profile3KjEUuzfn2Meta || {},
    alias: profile3KjEUuzfn2Meta?.alias || [],
    redirect: profile3KjEUuzfn2Meta?.redirect,
    component: () => import("/app/pages/[[platform]]/profile.vue").then(m => m.default || m)
  },
  {
    name: referral_45landWYUWOXMhX1Meta?.name ?? "platform-referral-land___ru",
    path: referral_45landWYUWOXMhX1Meta?.path ?? "/:platform?/referral-land",
    meta: referral_45landWYUWOXMhX1Meta || {},
    alias: referral_45landWYUWOXMhX1Meta?.alias || [],
    redirect: referral_45landWYUWOXMhX1Meta?.redirect,
    component: () => import("/app/pages/[[platform]]/referral-land.vue").then(m => m.default || m)
  },
  {
    name: referral_45landWYUWOXMhX1Meta?.name ?? "platform-referral-land___en",
    path: referral_45landWYUWOXMhX1Meta?.path ?? "/en/:platform?/referral-land",
    meta: referral_45landWYUWOXMhX1Meta || {},
    alias: referral_45landWYUWOXMhX1Meta?.alias || [],
    redirect: referral_45landWYUWOXMhX1Meta?.redirect,
    component: () => import("/app/pages/[[platform]]/referral-land.vue").then(m => m.default || m)
  },
  {
    name: referral_45landWYUWOXMhX1Meta?.name ?? "platform-referral-land___uz",
    path: referral_45landWYUWOXMhX1Meta?.path ?? "/uz/:platform?/referral-land",
    meta: referral_45landWYUWOXMhX1Meta || {},
    alias: referral_45landWYUWOXMhX1Meta?.alias || [],
    redirect: referral_45landWYUWOXMhX1Meta?.redirect,
    component: () => import("/app/pages/[[platform]]/referral-land.vue").then(m => m.default || m)
  },
  {
    name: referralwUTIYbnEEfMeta?.name ?? "platform-referral___ru",
    path: referralwUTIYbnEEfMeta?.path ?? "/:platform?/referral",
    meta: referralwUTIYbnEEfMeta || {},
    alias: referralwUTIYbnEEfMeta?.alias || [],
    redirect: referralwUTIYbnEEfMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/referral.vue").then(m => m.default || m)
  },
  {
    name: referralwUTIYbnEEfMeta?.name ?? "platform-referral___en",
    path: referralwUTIYbnEEfMeta?.path ?? "/en/:platform?/referral",
    meta: referralwUTIYbnEEfMeta || {},
    alias: referralwUTIYbnEEfMeta?.alias || [],
    redirect: referralwUTIYbnEEfMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/referral.vue").then(m => m.default || m)
  },
  {
    name: referralwUTIYbnEEfMeta?.name ?? "platform-referral___uz",
    path: referralwUTIYbnEEfMeta?.path ?? "/uz/:platform?/referral",
    meta: referralwUTIYbnEEfMeta || {},
    alias: referralwUTIYbnEEfMeta?.alias || [],
    redirect: referralwUTIYbnEEfMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/referral.vue").then(m => m.default || m)
  },
  {
    name: skibidisssezle1F5SsZMeta?.name ?? "platform-skibidisss___ru",
    path: skibidisssezle1F5SsZMeta?.path ?? "/:platform?/skibidisss",
    meta: skibidisssezle1F5SsZMeta || {},
    alias: skibidisssezle1F5SsZMeta?.alias || [],
    redirect: skibidisssezle1F5SsZMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/skibidisss.vue").then(m => m.default || m)
  },
  {
    name: skibidisssezle1F5SsZMeta?.name ?? "platform-skibidisss___en",
    path: skibidisssezle1F5SsZMeta?.path ?? "/en/:platform?/skibidisss",
    meta: skibidisssezle1F5SsZMeta || {},
    alias: skibidisssezle1F5SsZMeta?.alias || [],
    redirect: skibidisssezle1F5SsZMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/skibidisss.vue").then(m => m.default || m)
  },
  {
    name: skibidisssezle1F5SsZMeta?.name ?? "platform-skibidisss___uz",
    path: skibidisssezle1F5SsZMeta?.path ?? "/uz/:platform?/skibidisss",
    meta: skibidisssezle1F5SsZMeta || {},
    alias: skibidisssezle1F5SsZMeta?.alias || [],
    redirect: skibidisssezle1F5SsZMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/skibidisss.vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45conditions8ORfbpaGpxMeta?.name ?? "platform-terms-and-conditions___ru",
    path: terms_45and_45conditions8ORfbpaGpxMeta?.path ?? "/:platform?/terms-and-conditions",
    meta: terms_45and_45conditions8ORfbpaGpxMeta || {},
    alias: terms_45and_45conditions8ORfbpaGpxMeta?.alias || [],
    redirect: terms_45and_45conditions8ORfbpaGpxMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45conditions8ORfbpaGpxMeta?.name ?? "platform-terms-and-conditions___en",
    path: terms_45and_45conditions8ORfbpaGpxMeta?.path ?? "/en/:platform?/terms-and-conditions",
    meta: terms_45and_45conditions8ORfbpaGpxMeta || {},
    alias: terms_45and_45conditions8ORfbpaGpxMeta?.alias || [],
    redirect: terms_45and_45conditions8ORfbpaGpxMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45conditions8ORfbpaGpxMeta?.name ?? "platform-terms-and-conditions___uz",
    path: terms_45and_45conditions8ORfbpaGpxMeta?.path ?? "/uz/:platform?/terms-and-conditions",
    meta: terms_45and_45conditions8ORfbpaGpxMeta || {},
    alias: terms_45and_45conditions8ORfbpaGpxMeta?.alias || [],
    redirect: terms_45and_45conditions8ORfbpaGpxMeta?.redirect,
    component: () => import("/app/pages/[[platform]]/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: user_45settingsZ3Hy4lzZK1Meta?.name ?? "platform-user-settings___ru",
    path: user_45settingsZ3Hy4lzZK1Meta?.path ?? "/:platform?/user-settings",
    meta: user_45settingsZ3Hy4lzZK1Meta || {},
    alias: user_45settingsZ3Hy4lzZK1Meta?.alias || [],
    redirect: user_45settingsZ3Hy4lzZK1Meta?.redirect,
    component: () => import("/app/pages/[[platform]]/user-settings.vue").then(m => m.default || m)
  },
  {
    name: user_45settingsZ3Hy4lzZK1Meta?.name ?? "platform-user-settings___en",
    path: user_45settingsZ3Hy4lzZK1Meta?.path ?? "/en/:platform?/user-settings",
    meta: user_45settingsZ3Hy4lzZK1Meta || {},
    alias: user_45settingsZ3Hy4lzZK1Meta?.alias || [],
    redirect: user_45settingsZ3Hy4lzZK1Meta?.redirect,
    component: () => import("/app/pages/[[platform]]/user-settings.vue").then(m => m.default || m)
  },
  {
    name: user_45settingsZ3Hy4lzZK1Meta?.name ?? "platform-user-settings___uz",
    path: user_45settingsZ3Hy4lzZK1Meta?.path ?? "/uz/:platform?/user-settings",
    meta: user_45settingsZ3Hy4lzZK1Meta || {},
    alias: user_45settingsZ3Hy4lzZK1Meta?.alias || [],
    redirect: user_45settingsZ3Hy4lzZK1Meta?.redirect,
    component: () => import("/app/pages/[[platform]]/user-settings.vue").then(m => m.default || m)
  }
]