<template>
  <Intro />
  <!-- <HomeEvent /> -->
  <CasesSkeleton v-if="casesStore.showCasesSkeleton" />
  <Cases v-else />
</template>

<script setup lang="ts">
import promoCodeEventTarget, {
  CASE,
} from "~/event-targets/promoCodeEventTarget";
import Intro from "~/components/Home/Intro.vue";
import Cases from "~/components/Home/Cases.vue";
import CasesSkeleton from "~/components/Home/CasesSkeleton.vue";

// const HomeEvent = defineAsyncComponent(
//   () => import("@/components/Home/HomeEvent.vue"),
// );
const platform = usePlatform();
const { t } = useI18n();
const casesStore = useCasesStore();

definePageMeta({ ribbonVisible: true });

useSeoMeta({
  title: t("home.title", { platform: platform.title }),
  description: t("home.description", { platform: platform.title }),
  keywords: t("home.keywords", { platform: platform.title }),
  ogTitle: t("home.og.title", { platform: platform.title }),
  ogDescription: t("home.og.description", { platform: platform.title }),
});

onBeforeMount(async () => {
  if (
    !casesStore.cases[`${platform.code}`].paidCases.length ||
    casesStore.refreshCasesCollection
  ) {
    await pause();
    await getPaidCases();
    casesStore.refreshCasesCollection = false;
  }
  promoCodeEventTarget.addEventListener(CASE, getPaidCases);
});

onBeforeUnmount(() => {
  promoCodeEventTarget.removeEventListener(CASE, getPaidCases);
});

onUserLogin(async () => {
  await pause();
  getPaidCases();
});

onUserLogout(async () => {
  await pause();
  getPaidCases();
});

onLocaleChange(() => casesStore.dropCases());

function getPaidCases() {
  return casesStore.getCases(false);
}
</script>
