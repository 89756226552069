// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DjMoney } from './DjMoney';
import {
    DjMoneyFromJSON,
    DjMoneyFromJSONTyped,
    DjMoneyToJSON,
} from './DjMoney';

/**
 * 
 * @export
 * @interface InvoiceShort
 */
export interface InvoiceShort {
    /**
     * 
     * @type {DjMoney}
     * @memberof InvoiceShort
     */
    amountMoney: DjMoney;
    /**
     * 
     * @type {number}
     * @memberof InvoiceShort
     */
    amountBullcoins: number;
}

/**
 * Check if a given object implements the InvoiceShort interface.
 */
export function instanceOfInvoiceShort(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "amountMoney" in value;
    isInstance = isInstance && "amountBullcoins" in value;

    return isInstance;
}

export function InvoiceShortFromJSON(json: any): InvoiceShort {
    return InvoiceShortFromJSONTyped(json, false);
}

export function InvoiceShortFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceShort {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amountMoney': DjMoneyFromJSON(json['amount_money']),
        'amountBullcoins': json['amount_bullcoins'],
    };
}

export function InvoiceShortToJSON(value?: InvoiceShort | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount_money': DjMoneyToJSON(value.amountMoney),
        'amount_bullcoins': value.amountBullcoins,
    };
}

