<template>
  <div :class="$style['case-description']">
    <slot />
  </div>
</template>

<style lang="scss" module>
.case-description {
  position: absolute;
  left: 50%;
  top: 0;
  z-index: 2;
  transform: translateX(-50%);
  width: calc(100% + 20px);
  padding: 15px;
  border-radius: $br10;
  background-color: var(--button-tonal-default);
  font-size: 0.8125rem;
  color: var(--general-white);
  line-height: 130%;
  letter-spacing: 0.01em;
}
</style>
