<template>
  <div :class="['slide', $style.ReferralSlide]">
    <div :class="$style['banner-image']" />
    <Title>{{ t("referral_banner.title") }}</Title>
    <Content>{{ t("referral_banner.content") }}</Content>
    <Controller to="/referral-land">{{
      t("referral_banner.button")
    }}</Controller>
  </div>
</template>

<script lang="ts" setup>
import Title from "~/components/Home/Intro/Banner/Title.vue";
import Content from "~/components/Home/Intro/Banner/Content.vue";
import Controller from "~/components/Home/Intro/Banner/Controller.vue";

const { t } = useI18n();
</script>

<style lang="scss" module>
.ReferralSlide {
  background-size: contain;
  background-position: right top !important;

  background-image: linear-gradient(150deg, #3b2667 0%, #bc78ec 103.13%);

  .banner-image {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background-image: url("/banners/referral/large.webp");
    background-size: contain;
    width: 100%;
    background-repeat: no-repeat;
    background-position: right bottom !important;

    @media screen and (max-width: 930px) {
      background-position: right top !important;
    }

    @media screen and (max-width: 475px) {
      background-position: 0% top !important;
      background-size: cover;
    }
  }

  @media screen and (max-width: 734px) {
    .text {
      margin-top: 2rem;
    }
  }

  @media screen and (max-width: 600px) {
    .text {
      width: 85%;
      max-width: 400px;
    }
  }

  @media screen and (max-width: 375px) {
    .text {
      max-width: 80%;
    }
  }
}
</style>
