<template>
  <span :class="$style['case-price-discount']">
    <slot />
  </span>
</template>

<style lang="scss" module>
.case-price-discount {
  color: var(--bdrop-price);
}
</style>
