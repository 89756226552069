// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CurrencyEnum } from './CurrencyEnum';
import {
    CurrencyEnumFromJSON,
    CurrencyEnumFromJSONTyped,
    CurrencyEnumToJSON,
} from './CurrencyEnum';

/**
 * 
 * @export
 * @interface DjMoney
 */
export interface DjMoney {
    /**
     * 
     * @type {number}
     * @memberof DjMoney
     */
    amount: number;
    /**
     * 
     * @type {CurrencyEnum}
     * @memberof DjMoney
     */
    currency: CurrencyEnum;
}

/**
 * Check if a given object implements the DjMoney interface.
 */
export function instanceOfDjMoney(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "currency" in value;

    return isInstance;
}

export function DjMoneyFromJSON(json: any): DjMoney {
    return DjMoneyFromJSONTyped(json, false);
}

export function DjMoneyFromJSONTyped(json: any, ignoreDiscriminator: boolean): DjMoney {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'currency': CurrencyEnumFromJSON(json['currency']),
    };
}

export function DjMoneyToJSON(value?: DjMoney | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'currency': CurrencyEnumToJSON(value.currency),
    };
}

