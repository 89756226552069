<template>
  <Container>
    <!-- блок с серией платных кейсов -->
    <CasesBlock
      v-for="(caseBlock, index) in paidCasesPartOne"
      :key="index"
    >
      <GeneralName>{{ caseBlock.name }}</GeneralName>
      <CaseLinksContainer>
        <CaseLink
          v-for="(_case, index) in caseBlock.cases"
          :key="index"
          :_case="_case"
          :type="caseBlock.name"
        />
      </CaseLinksContainer>
    </CasesBlock>
    <!-- блок с серией бесплатных кейсов -->
    <CasesBlock v-if="showFreeCases">
      <GeneralName>
        {{ $t("home.cases.free_cases") }}
      </GeneralName>
      <CaseLinksContainer>
        <RaffleCaseLink
          v-if="vkCase && vkCaseIsEnabled"
          :free-case="vkCase"
        />
        <RaffleCaseLink
          v-if="tgCase && tgCaseIsEnabled"
          :free-case="tgCase"
        />
        <!-- <RaffleCaseLink
          v-if="tgCypherTapCase && cypherTapCaseIsEnabled"
          :free-case="tgCypherTapCase"
        /> -->
      </CaseLinksContainer>
    </CasesBlock>
    <!-- блок с серией платных кейсов -->
    <CasesBlock
      v-for="(caseBlock, index) in paidCasesPartTwo"
      :key="index"
    >
      <GeneralName>{{ caseBlock.name }}</GeneralName>
      <CaseLinksContainer>
        <CaseLink
          v-for="(_case, index) in caseBlock.cases"
          :key="index"
          :_case="_case"
          :type="caseBlock.name"
        />
      </CaseLinksContainer>
    </CasesBlock>
  </Container>
</template>

<script lang="ts" setup>
import Container from "./Cases/Container.vue";
import CasesBlock from "./Cases/CasesBlock.vue";
import GeneralName from "./Cases/GeneralName.vue";
import CaseLinksContainer from "./Cases/CaseLinksContainer.vue";
import CaseLink from "./Cases/CaseLink.vue";
import RaffleCaseLink from "./Cases/RaffleCaseLink.vue";

const vkCaseIsEnabled = useFlag("vk_case");
const tgCaseIsEnabled = useFlag("telegram_case");
const cypherTapCaseIsEnabled = useFlag("telegram_cypher_tap_case");

const platform = usePlatform();
const store = useCasesStore();

const paidCasesPartOne = computed(() =>
  store.cases[`${platform.code}`].paidCases.slice(0, 1),
);

const paidCasesPartTwo = computed(() =>
  store.cases[`${platform.code}`].paidCases.slice(1),
);

const vkCase = computed(
  () =>
    store.cases[`${platform.code}`].vkCase ?? {
      name: "Вконтакте",
      location: "vk",
      img: null,
    },
);
const tgCase = computed(
  () =>
    store.cases[`${platform.code}`].tgCase ?? {
      name: "Телеграм",
      location: "telegram",
      img: null,
    },
);
// const tgCypherTapCase = computed(() => store.cases[`${platform.code}`].tgCypherTapCase);

const showFreeCases = computed(() => {
  // let anyRaffleCaseAvailable = false;
  let anyRaffleCaseFlagsEnabled =
    vkCaseIsEnabled.value ||
    tgCaseIsEnabled.value ||
    cypherTapCaseIsEnabled.value;

  // Старая логика отображения бесплатных кейсов при
  // запросе данных с бэкенда
  // if (
  //   store.cases[`${platform.code}`].vkCase ||
  //   store.cases[`${platform.code}`].tgCase ||
  //   store.cases[`${platform.code}`].tgCypherTapCase
  // ) {
  //   anyRaffleCaseAvailable = true;
  // }

  // if (anyRaffleCaseAvailable && anyRaffleCaseFlagsEnabled) {
  //   return true;
  // }

  // По новой логике опираемся только на флаги кейсов и платформу
  if (anyRaffleCaseFlagsEnabled && platform.code != "cs2") {
    return true;
  }

  return false;
});
</script>
