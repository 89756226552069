import type { PlatformName } from "~/types";

export default defineNuxtRouteMiddleware(({ path, fullPath, params }) => {
  if (params.platform === undefined) {
    return showError({
      statusCode: 404,
    });
  }
  const platformParam = params.platform.toString();
  const platformStore = usePlatformStore();
  const platformCookie = useCookie("platform").value;
  if (!platformStore.isPlatformLegal(platformParam)) {
    throw createError({ statusCode: 404 });
  }
  platformStore.currentPlatformName =
    (platformParam as PlatformName) || platformStore.DEFAULT_PLATFORM;
  platformStore.updatePlatformPath({ path, fullPath });
  if (
    platformCookie &&
    platformStore.isPlatformLegal(platformCookie) &&
    platformCookie !== platformStore.currentPlatformName
  ) {
    return platformStore.changePlatform(platformCookie as PlatformName);
  }
});
