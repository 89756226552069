<template>
  <span :class="$style['case-price-old']">
    <slot />
  </span>
</template>

<style lang="scss" module>
@import "~/styles/mixins/line_through_with_icon.scss";

.case-price-old {
  @include line_through_with_icon(#4b4d5b);
  display: flex;
  align-items: center;
  top: 0;
  height: 100%;
  position: absolute;
  left: calc(100% + 10px);
  color: $grey-2;
  svg {
    margin-left: 3px;
    transform: translateY(-0.125em);
  }
}
</style>
