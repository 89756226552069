<template>
  <img
    v-if="src === 'vk'"
    :class="$style['case-image']"
    src="~/assets/case-dummy-img.svg"
    :data-src="`/vk.png`"
    :alt="caseDescription"
    class="lazyload"
    data-role="case-image"
  />
  <img
    v-else-if="src === 'telegram'"
    :class="$style['case-image']"
    src="~/assets/case-dummy-img.svg"
    :data-src="`/telegram.png`"
    :alt="caseDescription"
    class="lazyload"
    data-role="case-image"
  />
  <img
    v-else
    src="~/assets/case-dummy-img.svg"
    :data-src="src"
    :data-srcset="srcset"
    :class="$style['case-image']"
    class="lazyload"
    :alt="caseDescription"
    :title="title"
    data-role="case-image"
  />
</template>

<script lang="ts" setup>
const props = defineProps<{
  src: string;
  caseName: string;
  casePrice: number | string;
  caseType: string;
}>();
const { t } = useI18n();
const platform = usePlatform();
const coreStore = useCoreStore();
const caseDescription = computed(() =>
  t("home.cases.case_descriprion", {
    caseName: props.caseName,
    caseType: props.caseType,
    casePrice: props.casePrice,
  }),
);
const title = computed(() => {
  const getPhrase =
    Number(props.casePrice) === 0 ? t("misc.get") : t("misc.buy");
  return t("home.cases.image_title", {
    getPhrase,
    caseName: props.caseName,
    caseType: props.caseType,
    platform: platform.title,
    casePrice: props.casePrice,
  });
});
const isException = coreStore.imageSrcsetExceptions.some((name) =>
  props.src.toLowerCase().endsWith(name.toLowerCase()),
);
const srcset = isException
  ? `${props.src}`
  : `
  ${coreStore.cdn}/width=250,quality=90,format=auto/${props.src} 425w,
  ${coreStore.cdn}/width=600,quality=90,format=auto/${props.src} 1420w
`;
</script>

<style lang="scss" module>
.case-image {
  width: 100%;
  aspect-ratio: 1/1 auto;
  display: block;
  margin: auto;
  pointer-events: none;
  transition: 0.25s;
}
</style>
