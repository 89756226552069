<template>
  <h2 :class="$style['general-name']">
    <slot />
  </h2>
</template>

<style lang="scss" module>
.general-name {
  margin: 0;
  margin-bottom: 30px;
  font-size: 2rem;
  text-transform: uppercase;
  letter-spacing: -0.01em;
  color: var(--general-white);

  @media screen and (max-width: 425px) {
    font-size: 1.25rem;
  }
}
</style>
