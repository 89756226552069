// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Country,
  CountryDetail,
  GamelightInvoiceRequest,
  GamelightVaucherRequest,
  GetPayNotify,
  GetPayNotifyRequest,
  InvoiceRequest,
  OrderStatusRequest,
  PaymentDataResponse,
  Scenario,
  SelectActiveCountryRequest,
} from '../models/index';
import {
    CountryFromJSON,
    CountryToJSON,
    CountryDetailFromJSON,
    CountryDetailToJSON,
    GamelightInvoiceRequestFromJSON,
    GamelightInvoiceRequestToJSON,
    GamelightVaucherRequestFromJSON,
    GamelightVaucherRequestToJSON,
    GetPayNotifyFromJSON,
    GetPayNotifyToJSON,
    GetPayNotifyRequestFromJSON,
    GetPayNotifyRequestToJSON,
    InvoiceRequestFromJSON,
    InvoiceRequestToJSON,
    OrderStatusRequestFromJSON,
    OrderStatusRequestToJSON,
    PaymentDataResponseFromJSON,
    PaymentDataResponseToJSON,
    ScenarioFromJSON,
    ScenarioToJSON,
    SelectActiveCountryRequestFromJSON,
    SelectActiveCountryRequestToJSON,
} from '../models/index';

export interface PaymentsApiCountriesActiveSelectRequest {
    selectActiveCountryRequest: SelectActiveCountryRequest;
}

export interface PaymentsApiGamelightRequest {
    gamelightInvoiceRequest: GamelightInvoiceRequest;
}

export interface PaymentsApiGetpayNotifyCreateRequest {
    getPayNotifyRequest: GetPayNotifyRequest;
}

export interface PaymentsApiPayselectionStatusRequest {
    orderStatusRequest: OrderStatusRequest;
}

export interface PaymentsApiPayselectionTermCreateRequest {
    orderId: string;
}

export interface PaymentsApiTopUpBalanceCreateRequest {
    paymentMethod: string;
    invoiceRequest?: InvoiceRequest;
}

export interface PaymentsApiTopUpBalancePaymentDataRetrieveRequest {
    paymentMethod: string;
}

export interface PaymentsApiTopUpBalanceVoucherRequest {
    gamelightVaucherRequest: GamelightVaucherRequest;
}

/**
 * 
 */
export class PaymentsApi extends runtime.BaseAPI {

    /**
     */
    async countriesActiveRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CountryDetail>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/payments/countries/active/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountryDetailFromJSON(jsonValue));
    }

    /**
     */
    async countriesActiveRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CountryDetail> {
        const response = await this.countriesActiveRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async countriesActiveSelectRaw(requestParameters: PaymentsApiCountriesActiveSelectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CountryDetail>> {
        if (requestParameters['selectActiveCountryRequest'] == null) {
            throw new runtime.RequiredError(
                'selectActiveCountryRequest',
                'Required parameter "selectActiveCountryRequest" was null or undefined when calling countriesActiveSelect().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/payments/countries/active/select/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SelectActiveCountryRequestToJSON(requestParameters['selectActiveCountryRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountryDetailFromJSON(jsonValue));
    }

    /**
     */
    async countriesActiveSelect(requestParameters: PaymentsApiCountriesActiveSelectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CountryDetail> {
        const response = await this.countriesActiveSelectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async countriesListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Country>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/payments/countries/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CountryFromJSON));
    }

    /**
     */
    async countriesList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Country>> {
        const response = await this.countriesListRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async gamelightRaw(requestParameters: PaymentsApiGamelightRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['gamelightInvoiceRequest'] == null) {
            throw new runtime.RequiredError(
                'gamelightInvoiceRequest',
                'Required parameter "gamelightInvoiceRequest" was null or undefined when calling gamelight().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/payments/gamelight/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GamelightInvoiceRequestToJSON(requestParameters['gamelightInvoiceRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async gamelight(requestParameters: PaymentsApiGamelightRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.gamelightRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getpayNotifyCreateRaw(requestParameters: PaymentsApiGetpayNotifyCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPayNotify>> {
        if (requestParameters['getPayNotifyRequest'] == null) {
            throw new runtime.RequiredError(
                'getPayNotifyRequest',
                'Required parameter "getPayNotifyRequest" was null or undefined when calling getpayNotifyCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/payments/getpay/notify/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetPayNotifyRequestToJSON(requestParameters['getPayNotifyRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPayNotifyFromJSON(jsonValue));
    }

    /**
     */
    async getpayNotifyCreate(requestParameters: PaymentsApiGetpayNotifyCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPayNotify> {
        const response = await this.getpayNotifyCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async payselectionStatusRaw(requestParameters: PaymentsApiPayselectionStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Scenario>> {
        if (requestParameters['orderStatusRequest'] == null) {
            throw new runtime.RequiredError(
                'orderStatusRequest',
                'Required parameter "orderStatusRequest" was null or undefined when calling payselectionStatus().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/payments/payselection/status/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrderStatusRequestToJSON(requestParameters['orderStatusRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ScenarioFromJSON(jsonValue));
    }

    /**
     */
    async payselectionStatus(requestParameters: PaymentsApiPayselectionStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Scenario> {
        const response = await this.payselectionStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async payselectionTermCreateRaw(requestParameters: PaymentsApiPayselectionTermCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['orderId'] == null) {
            throw new runtime.RequiredError(
                'orderId',
                'Required parameter "orderId" was null or undefined when calling payselectionTermCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/payments/payselection/term/{order_id}/`.replace(`{${"order_id"}}`, encodeURIComponent(String(requestParameters['orderId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async payselectionTermCreate(requestParameters: PaymentsApiPayselectionTermCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.payselectionTermCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async topUpBalanceCreateRaw(requestParameters: PaymentsApiTopUpBalanceCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Scenario>> {
        if (requestParameters['paymentMethod'] == null) {
            throw new runtime.RequiredError(
                'paymentMethod',
                'Required parameter "paymentMethod" was null or undefined when calling topUpBalanceCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/payments/top-up-balance/{payment_method}/`.replace(`{${"payment_method"}}`, encodeURIComponent(String(requestParameters['paymentMethod']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InvoiceRequestToJSON(requestParameters['invoiceRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ScenarioFromJSON(jsonValue));
    }

    /**
     */
    async topUpBalanceCreate(requestParameters: PaymentsApiTopUpBalanceCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Scenario> {
        const response = await this.topUpBalanceCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async topUpBalancePaymentDataRetrieveRaw(requestParameters: PaymentsApiTopUpBalancePaymentDataRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaymentDataResponse>> {
        if (requestParameters['paymentMethod'] == null) {
            throw new runtime.RequiredError(
                'paymentMethod',
                'Required parameter "paymentMethod" was null or undefined when calling topUpBalancePaymentDataRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/payments/top-up-balance/{payment_method}/payment-data/`.replace(`{${"payment_method"}}`, encodeURIComponent(String(requestParameters['paymentMethod']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentDataResponseFromJSON(jsonValue));
    }

    /**
     */
    async topUpBalancePaymentDataRetrieve(requestParameters: PaymentsApiTopUpBalancePaymentDataRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaymentDataResponse> {
        const response = await this.topUpBalancePaymentDataRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async topUpBalanceVoucherRaw(requestParameters: PaymentsApiTopUpBalanceVoucherRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['gamelightVaucherRequest'] == null) {
            throw new runtime.RequiredError(
                'gamelightVaucherRequest',
                'Required parameter "gamelightVaucherRequest" was null or undefined when calling topUpBalanceVoucher().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/payments/top-up-balance/voucher/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GamelightVaucherRequestToJSON(requestParameters['gamelightVaucherRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async topUpBalanceVoucher(requestParameters: PaymentsApiTopUpBalanceVoucherRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.topUpBalanceVoucherRaw(requestParameters, initOverrides);
    }

}
