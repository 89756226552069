<template>
  <div :class="$style['case-links-container']">
    <slot />
  </div>
</template>

<style lang="scss" module>
.case-links-container {
  --case-in-row: 4;
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(var(--case-in-row), 1fr);

  @media screen and (max-width: 800px) {
    --case-in-row: 3;
  }

  @media screen and (max-width: 425px) {
    --case-in-row: 2;
  }
}
</style>
