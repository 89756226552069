<template>
  <svg
    width="137"
    height="37"
    viewBox="0 0 137 37"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2255_49262)">
      <path
        d="M25.9016 16.6864C26.9284 17.6026 27.7267 18.9776 27.7267 20.8864V21.2681C27.7267 25.2395 24.3424 27.4542 20.5391 27.4542H8.29435C7.26755 27.4542 6.43047 26.6138 6.43047 25.6211C6.43047 24.6284 7.26755 23.7505 8.29435 23.7505H20.5772C22.745 23.7505 23.6957 22.4533 23.6957 21.2681V20.8864C23.6957 19.7026 22.7437 18.3665 20.5772 18.3665H18.257C18.0143 18.3712 17.7732 18.3273 17.5476 18.2373C17.322 18.1473 17.1166 18.0131 16.9433 17.8424C16.77 17.6718 16.6323 17.4681 16.5384 17.2434C16.4444 17.0187 16.396 16.7775 16.396 16.5338C16.396 16.2901 16.4444 16.0488 16.5384 15.8241C16.6323 15.5994 16.77 15.3958 16.9433 15.2251C17.1166 15.0545 17.322 14.9202 17.5476 14.8302C17.7732 14.7402 18.0143 14.6963 18.257 14.701H20.6153C22.745 14.701 23.6957 13.4038 23.6957 12.2186V11.837C23.6957 10.6532 22.7437 9.27884 20.6527 9.27884H4.86987C2.51435 9.27884 0.610352 7.21635 0.610352 4.88759C0.610352 2.02358 3.50103 0 4.83179 0C5.06027 0 5.21191 0.0764646 5.21191 0.191162C5.21191 0.532521 4.25991 1.71841 4.25991 3.16919C4.25991 4.61996 5.24999 5.61264 6.42911 5.61264H27.1188C28.2972 5.61264 29.286 4.61996 29.286 3.16919C29.286 1.71841 28.334 0.53457 28.334 0.191162C28.334 0.0764646 28.4863 0 28.7141 0C30.0455 0 32.9362 2.02358 32.9362 4.88759C32.9362 7.21635 31.0322 9.27884 28.6767 9.27884H27.2323C27.1188 9.27884 27.0807 9.35531 27.1188 9.46932C27.5131 10.2372 27.7214 11.0877 27.7267 11.9517V12.2931C27.7267 13.5902 27.2704 15.0806 25.9016 16.3027L25.8254 16.3784C25.7493 16.4549 25.7873 16.5696 25.8635 16.6461L25.9016 16.6864Z"
      />
      <path
        d="M36.9303 27.4549C33.7737 27.4549 30.3506 24.8967 30.3506 20.3143V15.9995C30.3506 15.0068 31.1877 14.0879 32.1764 14.0879C33.1651 14.0879 34.0015 14.9276 34.0015 15.9585V20.3116C34.0015 22.8697 35.5988 23.7484 36.9303 23.7484H40.7335C42.1023 23.7484 43.6235 22.9079 43.6235 20.3116V15.9613C43.6235 14.9686 44.4987 14.0906 45.4874 14.0906C46.4761 14.0906 47.3132 15.0068 47.3132 16.0022V20.317C47.3132 24.937 43.8901 27.4576 40.7335 27.4576L36.9303 27.4549Z"
      />
      <path
        d="M55.984 27.4541C52.8274 27.4541 49.4424 24.8959 49.4424 20.3135V5.68896C49.4424 4.69628 50.2795 3.77734 51.2682 3.77734C52.2569 3.77734 53.0933 4.65532 53.0933 5.68896V20.504C53.0933 22.8334 54.6525 23.7496 56.0221 23.7496H59.3309C60.3197 23.7496 61.2349 24.6276 61.2349 25.6203C61.2349 26.5761 60.3605 27.4534 59.2956 27.4534L55.984 27.4541Z"
      />
      <path
        d="M68.5724 27.4541C65.4158 27.4541 62.0308 24.8959 62.0308 20.3135V5.68896C62.0308 4.69628 62.8678 3.77734 63.8566 3.77734C64.8453 3.77734 65.6817 4.65532 65.6817 5.68896V20.504C65.6817 22.8334 67.2416 23.7496 68.6104 23.7496H71.9193C72.908 23.7496 73.8233 24.6276 73.8233 25.6203C73.8233 26.5761 72.9488 27.4534 71.884 27.4534L68.5724 27.4541Z"
      />
      <path
        d="M85.7628 14.0895C86.1028 14.0895 86.3333 13.8601 86.3333 13.5167V6.45263C86.3333 5.42172 87.1697 4.54102 88.1584 4.54102C89.1471 4.54102 90.0223 5.41968 90.0223 6.45263V15.8837C90.0223 17.0293 89.2614 17.7953 88.1183 17.7953H81.3128C78.9927 17.7953 78.0801 19.3608 78.0801 20.6969V20.8498C78.0801 22.1866 78.9927 23.7521 81.3128 23.7521H88.0822C89.1471 23.7521 89.9862 24.63 89.9862 25.6227C89.9862 26.6154 89.1111 27.4558 88.0822 27.4558H81.5032C76.9391 27.4558 74.3911 23.9808 74.3911 20.8116V20.6969C74.3911 17.5277 76.9391 14.0916 81.5032 14.0916L85.7628 14.0895Z"
      />
      <path
        d="M103.942 15.9223C103.942 16.9539 103.028 17.7936 102.038 17.7936H98.7343C97.4035 17.7936 95.8062 18.4811 95.8062 21.0392V25.5452C95.8062 26.5761 94.9691 27.4568 93.9804 27.4568C92.9917 27.4568 92.1553 26.5399 92.1553 25.5097V21.0392C92.1553 16.4568 95.5777 14.0898 98.7343 14.0898H102.005C103.067 14.0898 103.942 14.9678 103.942 15.9223Z"
      />
      <path
        d="M104.74 20.7716C104.74 17.259 107.479 14.0898 111.282 14.0898H113.374C117.329 14.0898 119.991 17.2986 119.991 20.7716C119.991 24.3218 117.177 27.4541 113.374 27.4541H111.282C107.479 27.4541 104.74 24.2849 104.74 20.7716ZM113.376 23.7503C114.745 23.7503 116.342 23.1393 116.342 20.8863V20.6569C116.342 18.404 114.745 17.7554 113.376 17.7554H111.285C109.953 17.7554 108.394 18.4046 108.394 20.6569V20.8863C108.394 23.1393 109.953 23.7503 111.285 23.7503H113.376Z"
      />
      <path
        d="M125.239 27.4549C124.899 27.4549 124.668 27.6836 124.668 28.0277V35.0924C124.668 36.124 123.832 37.0041 122.843 37.0041C121.854 37.0041 120.979 36.1261 120.979 35.0924V25.66C120.979 24.5144 121.74 23.7484 122.883 23.7484H129.69C132.01 23.7484 132.923 22.1829 132.923 20.8461V20.6932C132.923 19.3189 132.01 17.7534 129.69 17.7534H122.92C121.856 17.7534 121.016 16.9137 121.016 15.9203C121.016 14.927 121.891 14.0879 122.92 14.0879H129.5C134.102 14.0879 136.612 17.524 136.612 20.7314V20.8461C136.612 24.0153 134.064 27.4521 129.5 27.4521L125.239 27.4549Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_2255_49262">
        <rect
          width="136"
          height="37"
          fill="white"
          transform="translate(0.610352)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
