<template>
  <Container :to="`/case/open/?id=${_case.id}`">
    <Transition name="case-description">
      <CaseDescription
        v-if="descriptionIsRequired"
        @click="descriptionIsRequired = false"
        @mouseleave="descriptionIsRequired = false"
      >
        {{ _case.description }}
      </CaseDescription>
    </Transition>
    <CaseImage
      :src="`${_case.img}`"
      :case-name="_case.name"
      :case-price="_case.price"
      :case-type="type"
    />
    <!--
    <SpineImage
      v-else
      <SpineImage
      v-else
        v-bind="casesStore.getAnimatedCaseSettings(_case.name)!"
      /> 
    -->
    <CaseName>{{ _case.name }}</CaseName>
    <CasePrice>
      <template v-if="typeof _case.discountPrice === 'number'">
        <CasePriceDiscount>
          {{ _case.discountPrice }} <IconBullcoin />
        </CasePriceDiscount>
        <CasePriceOld>{{ _case.price }} <IconBullcoin /></CasePriceOld>
      </template>
      <template v-else-if="_case.oldPrice !== _case.price">
        <CasePriceDiscount>
          {{ _case.price }} <IconBullcoin />
        </CasePriceDiscount>
        <CasePriceOld>{{ _case.oldPrice }} <IconBullcoin /></CasePriceOld>
      </template>
      <template v-else>{{ _case.price }} <IconBullcoin /></template>
    </CasePrice>
    <OpenCaseExpression />
  </Container>
</template>

<script lang="ts" setup>
import Container from "./CaseLink/Container.vue";
import CaseDescription from "./CaseLink/CaseDescription.vue";
import CaseImage from "./CaseLink/CaseImage.vue";

import CaseName from "./CaseLink/CaseName.vue";
import CasePrice from "./CaseLink/CasePrice.vue";
import CasePriceOld from "./CaseLink/CasePriceOld.vue";
import CasePriceDiscount from "./CaseLink/CasePriceDiscount.vue";
import OpenCaseExpression from "./CaseLink/OpenCaseExpression.vue";
import type { CaseList } from "~/api_gen";

defineProps<{
  _case: CaseList;
  type: string;
}>();

const descriptionIsRequired = ref(false);
/** @note на случай если spine кейсы вернутся

import SpineImage from "./CaseLink/SpineImage.vue";
const casesStore = useCasesStore();
*/
</script>

<style>
.case-description-enter-from,
.case-description-leave-to {
  opacity: 0;
}

.case-description-enter-active,
.case-description-leave-active {
  transition: 0.25s;
}
</style>
