import type { Notification } from "~/api_gen";
import type { INotificationOptions } from "~/types";

export const useNotificationsStore = defineStore("notificationsStore", () => {
  const notificationIsRequired = ref(false);
  const notificationsWindowIsRequired = ref(false);
  const notification = ref<INotificationOptions>();
  const notifications = ref<Notification[]>([]);
  const api = useApi();
  const unreadNotifications = computed<Notification[]>(() =>
    notifications.value.filter((item) => {
      if (!item.isViewed) {
        return item;
      }
    }),
  );
  const hasUnreadNotifications = computed(() =>
    notifications.value.some((item) => !item.isViewed),
  );

  function getNoScannedNotifications() {
    return api.notifications
      .allList()
      .then((response) => fillNotifications(response.results as Notification[]))
      .catch(() => {});
  }

  function fillNotifications(value: Notification[]) {
    notifications.value = value;
    notifications.value.forEach((notification) => {
      notification.isViewed = notification.read;
    });
  }

  function markNotificationsAsViewed() {
    notifications.value.forEach((item) => {
      if (!item.read) {
        item.isViewed = true;
      }
    });
  }

  function showNotification(settings: INotificationOptions) {
    notificationIsRequired.value = true;
    notification.value = settings;
  }

  function hideNotification() {
    notificationIsRequired.value = false;
    notification.value = undefined;
  }

  function scanAllNotifications() {
    api.notifications
      .scanedAllCreate()
      .then(() => markNotificationsAsViewed())
      .catch(() => {});
  }

  function showNotificationsWindow() {
    document.documentElement.style.overflow = "hidden";
    notificationsWindowIsRequired.value = true;
  }

  function hideNotificationsWindow() {
    notificationsWindowIsRequired.value = false;
    document.documentElement.style.overflow = "";
  }

  function clearNotifications() {
    notifications.value = [];
  }

  return {
    notificationIsRequired,
    notificationsWindowIsRequired,
    notification,
    notifications,
    unreadNotifications,
    hasUnreadNotifications,
    getNoScannedNotifications,
    fillNotifications,
    markNotificationsAsViewed,
    showNotification,
    hideNotification,
    scanAllNotifications,
    showNotificationsWindow,
    hideNotificationsWindow,
    clearNotifications,
  };
});
