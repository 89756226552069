// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `ru` - Russian
 * * `en` - English
 * * `uz` - Uzbekistan
 * @export
 */
export const LanguageEc4Enum = {
    Ru: 'ru',
    En: 'en',
    Uz: 'uz'
} as const;
export type LanguageEc4Enum = typeof LanguageEc4Enum[keyof typeof LanguageEc4Enum];


export function LanguageEc4EnumFromJSON(json: any): LanguageEc4Enum {
    return LanguageEc4EnumFromJSONTyped(json, false);
}

export function LanguageEc4EnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): LanguageEc4Enum {
    return json as LanguageEc4Enum;
}

export function LanguageEc4EnumToJSON(value?: LanguageEc4Enum | null): any {
    return value as any;
}

