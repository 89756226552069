<template>
  <div :class="$style['case-name']">
    <slot />
  </div>
</template>

<style lang="scss" module>
.case-name {
  position: absolute;
  left: 0;
  bottom: 75px;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--general-white);
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.01em;
}
</style>
