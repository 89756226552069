export const useCoreStore = defineStore("coreStore", () => {
  const showBossBattle = ref(true);
  const showExchanger = ref(false);
  const api = useApi();
  const refreshMutex = ref<Promise<void>>();
  const vk = ref("https://vk.com/bulldrop");
  const cdn = computed(() => {
    if (useRequestURL().hostname === "bulldrop.vip") {
      return `https://bulldrop.vip/cdn-cgi/image`;
    } else {
      return `https://dev.bulltrade.org/cdn-cgi/image`;
    }
  });
  const tg = ref("https://t.me/bulldron");
  const tgSupportBot = ref("https://t.me/BulldropSupportBot");
  const discord = ref("https://discord.gg/bulldrop");
  const gamelight = ref("https://gamelight.ru");
  const gamelightPubg = ref("https://gamelight.ru/products/15");
  const gamelightCS2 = ref("https://gamelight.ru/products/steam");
  const imageSrcsetExceptions = ["DragonEgg.webp"];
  const percent = ref<number | null>(null);
  const topUpBalanceFormIsRequired = ref(false);
  const withdrawFormIsRequired = ref(false);
  const headerMenuIsRequired = ref(false);
  const languageSubmenuIsRequired = ref(false);
  const platformSwitcherIsRequired = ref(false);
  const topUpInitialSum = ref(101);
  const audioIsRequired = ref(true);
  const intersectionObserverIsSupported = computed(() => {
    if ("IntersectionObserver" in window) {
      return true;
    } else {
      return false;
    }
  });
  const gameStats = reactive({
    players: 0,
    casesOpened: 0,
    ladderBets: 0,
    crashBets: 0,
    minerBets: 0,
    wheelBets: 0,
  });
  let gameStatsFetched = false;

  async function getGameStats() {
    try {
      if (gameStatsFetched) return;
      const data = await api.games.overviewFooterValuesRetrieve();
      Object.assign(gameStats, data);
      gameStatsFetched = true;
    } catch (error) {
      /* empty */
    }
  }

  function toggleAudio() {
    audioIsRequired.value = !audioIsRequired.value;
    useCookie("audioIsRequired").value = JSON.stringify(audioIsRequired.value);
  }

  function showMenuHeader() {
    headerMenuIsRequired.value = true;
    languageSubmenuIsRequired.value = false;
  }

  function hideMenuHeader() {
    headerMenuIsRequired.value = false;
    document.getElementById("layout")!.style.removeProperty("overflow");
  }

  function toggleLanguageSubmenu() {
    languageSubmenuIsRequired.value = !languageSubmenuIsRequired.value;
  }

  function showLanguageSubmenu() {
    languageSubmenuIsRequired.value = true;
  }

  function hideLanguageSubmenu() {
    languageSubmenuIsRequired.value = false;
  }

  function showPlatformSwitcher() {
    platformSwitcherIsRequired.value = true;
  }

  function hidePlatfromSwitcher() {
    platformSwitcherIsRequired.value = false;
  }

  function togglePlatformSwitcher() {
    platformSwitcherIsRequired.value = !platformSwitcherIsRequired.value;
  }

  function showLanguageSubmenuMobile() {
    headerMenuIsRequired.value = false;
    languageSubmenuIsRequired.value = true;
  }

  function toggleMenuHeader() {
    headerMenuIsRequired.value = !headerMenuIsRequired.value;
    if (headerMenuIsRequired.value) {
      document.getElementById("layout")!.style.overflow = "hidden";
    } else {
      document.getElementById("layout")!.style.removeProperty("overflow");
    }
  }

  function showTopUpBalanceForm(initialSum: number = 0) {
    document.documentElement.style.overflow = "hidden";
    topUpBalanceFormIsRequired.value = true;
    topUpInitialSum.value = initialSum;
  }

  function hideTopUpBalanceForm() {
    topUpBalanceFormIsRequired.value = false;
    topUpInitialSum.value = 0;
    document.documentElement.style.overflow = "";
  }

  function showWithdrawForm() {
    document.documentElement.style.overflow = "hidden";
    withdrawFormIsRequired.value = true;
  }

  function hideWithdrawForm() {
    withdrawFormIsRequired.value = false;
    document.documentElement.style.overflow = "";
  }

  return {
    showBossBattle,
    showExchanger,
    refreshMutex,
    vk,
    cdn,
    tg,
    tgSupportBot,
    discord,
    gamelight,
    gamelightPubg,
    gamelightCS2,
    percent,
    topUpBalanceFormIsRequired,
    withdrawFormIsRequired,
    headerMenuIsRequired,
    platformSwitcherIsRequired,
    languageSubmenuIsRequired,
    topUpInitialSum,
    audioIsRequired,
    intersectionObserverIsSupported,
    imageSrcsetExceptions,
    gameStats,
    getGameStats,
    toggleAudio,
    showMenuHeader,
    hideMenuHeader,
    toggleLanguageSubmenu,
    showLanguageSubmenu,
    hideLanguageSubmenu,
    showPlatformSwitcher,
    hidePlatfromSwitcher,
    togglePlatformSwitcher,
    showLanguageSubmenuMobile,
    toggleMenuHeader,
    showTopUpBalanceForm,
    hideTopUpBalanceForm,
    showWithdrawForm,
    hideWithdrawForm,
  };
});
