<template>
  <div :class="['slide', $style['boss-battle-slide']]">
    <div :class="$style['banner-image']" />
    <Title>{{ t(`${BATTLE_KEY}.banner.title`) }}</Title>
    <Content>{{ t(`${BATTLE_KEY}.banner.content`) }}</Content>
    <Controller to="/boss-battle"
      >{{ t(`${BATTLE_KEY}.banner.attack`) }}
    </Controller>
  </div>
</template>

<script lang="ts" setup>
import Title from "~/components/Home/Intro/Banner/Title.vue";
import Content from "~/components/Home/Intro/Banner/Content.vue";
import Controller from "~/components/Home/Intro/Banner/Controller.vue";
import { BATTLE_KEY } from "~/components/BossBattle/composables/initMainView";

const { t } = useI18n();
</script>

<style lang="scss" module>
.boss-battle-slide {
  background-size: contain;
  background-position: center top !important;

  background: linear-gradient(
    119deg,
    #0c2946 -10.17%,
    #1e426d 30.97%,
    #598ac2 72.34%,
    #76afe3 98.61%,
    #87c5ff 107.36%
  );

  .banner-image {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background-image: url("/banners/boss-battle/large.webp");
    background-size: contain;
    width: 100%;
    background-repeat: no-repeat;
    background-position: right bottom !important;

    @media screen and (max-width: 930px) {
      background-image: url("/banners/boss-battle/medium.webp");
    }

    @media screen and (max-width: 740px) {
      background-image: url("/banners/boss-battle/small.webp");
      background-size: cover;
      background-position: right 0 !important;
    }

    @media screen and (max-width: 375px) {
      background-image: url("/banners/boss-battle/extra_small.webp");
    }
  }

  @media screen and (max-width: 600px) {
    .text {
      width: 85%;
      max-width: 400px;
    }
  }

  @media screen and (max-width: 375px) {
    .text {
      max-width: 80%;
    }
  }

  @media screen and (max-width: 800px) {
    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.2);
      pointer-events: none;
    }
  }
}
</style>
